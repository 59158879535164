export default function ThreeD(){

    return (


<svg className="icon icon-tabler icon-tabler-3d-cube-sphere" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M6 17.6l-2 -1.1v-2.5"></path>
   <path d="M4 10v-2.5l2 -1.1"></path>
   <path d="M10 4.1l2 -1.1l2 1.1"></path>
   <path d="M18 6.4l2 1.1v2.5"></path>
   <path d="M20 14v2.5l-2 1.12"></path>
   <path d="M14 19.9l-2 1.1l-2 -1.1"></path>
   <path d="M12 12l2 -1.1"></path>
   <path d="M18 8.6l2 -1.1"></path>
   <path d="M12 12l0 2.5"></path>
   <path d="M12 18.5l0 2.5"></path>
   <path d="M12 12l-2 -1.12"></path>
   <path d="M6 8.6l-2 -1.1"></path>
</svg>





    );
}



