import React from 'react';
import Android_Sr from './Android_Sr';
import IOS_Sr from './IOS_Sr';



const Product_Section2 = () => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);


  if (isIOS) {
    // Render iOS-specific content
    return (
      <IOS_Sr></IOS_Sr>
    ) ;
  } else {
    // Render content for other devices or browsers
    return (
   <Android_Sr></Android_Sr>
    );
    
  }
};

export default Product_Section2;
