
import styled from 'styled-components'
import Install from '../svgs/Install.js';
import Eth from '../svgs/Eth.js';
import AI from '../svgs/AI.js';


const ItemWrapper = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; 
 
  
  /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    margin:2%;
    }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
  }
  
  @media screen and (max-width: 631px) { 
  }
  `;



  const SvgWrapper = styled.main`
  padding-bottom: 10px;
  /* Media Queries */
@media screen and (min-width: 1440px) and (max-width: 3500px) {
  transform: scale(2.5);
  padding-top:20px;
  
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  transform: scale(2.5);
  padding-top:20px;
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
  transform: scale(2.5);
  padding-top:10px;
}

@media screen and (max-width: 631px) {
  transform: scale(2.5);
  padding-top:60px;
  padding-bottom:30px;
  
}
  `;

  const TopicWrapper = styled.main`
  
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Add align-items property to center horizontally and vertically */


  /* Media Queries */
@media screen and (min-width: 1440px) and (max-width: 3500px) {
  font-size: 35px;
  padding-top:30px;
  text-align: center;
 
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  font-size: 35px;
  padding-top:30px;
  text-align: center;
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
  padding-top:20px;
  font-size: 22px;
  text-align: center;
}

@media screen and (max-width: 631px) {
  padding-top:10px;
  padding-bottom:15px;
  font-size: 25px;
  text-align: center;

  
}
  `;




  const FeaturesWrapper = styled.div`
  position: relative;
  display: flex;
  
  
  
  /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    margin-top: 7%;
    flex-direction: row;
    align-items: center;
 
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    margin-top: 10%;
    flex-direction: row;
  align-items: center;

  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    margin-top: 10%;
    flex-direction: row;
  align-items: center;
  padding-top: 20px;
  }
  
  @media screen and (max-width: 631px) {
    margin-top: 20%;
    flex-direction: column;
   
    
  }
  
  
  `;
  



  const FeatureWrapper = styled.div`

/* Media Queries */
@media screen and (min-width: 1440px) and (max-width: 3500px) {
  font-size: 30px;
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  font-size: 20px;
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
  font-size: 15px;
}

@media screen and (max-width: 631px) {
  font-size: 25px;
  
}


`;

  const DescriptionWrapper = styled.main`
  /* Media Queries */
  position: relative;
  display:flex;
  text-align: center;

  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    font-size: 19px;
  }

  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    font-size: 15px;
  }

  @media screen and (min-width: 631px) and (max-width: 1000px) {
    font-size: 15px;
  }

  @media screen and (max-width: 631px) {
    margin-bottom:15%;
    font-size: 15px;
    
  }
  `;





export default function ModuleLine()
{





    return(


<div className='ML_background'>

<div className="CL_response"> 
<TopicWrapper>
                      <p className="font-Kanit text-gray-500 ">
                      Experience the capabilities of our unparalled <span className="text-orange-600">Automation</span> Field Service Engineering 
                      </p>
                      </TopicWrapper>
                      <div className='text-black font-Montserrat text-center'> Blending AI with Industrial Automation to eliminate uncertainities </div>





                      <FeaturesWrapper>    

<ItemWrapper>
<SvgWrapper>
  <Install/>
  </SvgWrapper>
  <FeatureWrapper>
  <div className='text-center font-Kanit py-5  text-slate-800 '>Installation</div>
  </FeatureWrapper>
  <DescriptionWrapper>
  <div className='text-center text-slate-600 font-EBGaramond '> Committed to Industry Standards, OEM SOPs, Machine Layout, and Electrical Diagrams for Seamless Equipment Integration and Optimal Performance.</div>
  </DescriptionWrapper>
 </ItemWrapper>

  
  <ItemWrapper>
  <SvgWrapper>
    <Eth></Eth>
    </SvgWrapper>
    <FeatureWrapper>
  <div className='text-center font-Kanit py-5  text-slate-800'>Startup & Commissioning</div>
  </FeatureWrapper>
  <DescriptionWrapper>
  <div className='text-center text-slate-600 font-EBGaramond'> Ensuring Flawless Equipment Startup and PLC Program Commissioning with Uncompromising Adherence to OEM Standards, Rigorous Testing, and Safety Protocols.</div>
  </DescriptionWrapper>
  </ItemWrapper>


  <ItemWrapper>
  <SvgWrapper>
    <AI></AI>
    </SvgWrapper>
    <FeatureWrapper>
  <div className='text-center font-Kanit py-5  text-slate-800'>Troubleshooting</div>
  </FeatureWrapper>
  <DescriptionWrapper>
  <div className='text-center text-slate-600 font-EBGaramond'>Unleashing the Power of AL/ML for Advanced Troubleshooting: Detect Hidden Failure Patterns, Enhance Equipment Digital Twin Fidelity, and Drive Optimal Performance.</div>
  </DescriptionWrapper>
  </ItemWrapper>
</FeaturesWrapper> 
                      </div>

<style>{`


.ML_background{
    position: relative;
    background-color: #FAFAFA;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

.ML_response {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  
  padding-bottom: 0px;


     
     
   
     
   }
   
       @media screen and (min-width: 1440px) and (max-width: 3500px) {
        .CL_response {

          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
      
      @media screen and (min-width: 1000px) and (max-width: 1440px) {
        .CL_response {

          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
      
      @media screen and (min-width: 631px) and (max-width: 1000px) {
        .CL_response {

          display: flex;
          flex-direction: column;
      
          
        }
      }
      
      
      @media screen and (max-width: 631px) {
        .CL_response {
 
 
          display: flex;
          flex-direction: column;
          
         
          
        }
      }
      
 
 
  `}</style>
</div>


    );
}