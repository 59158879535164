
import styled from 'styled-components'


const TopicWrapper = styled.main`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom:3%;
    
    
    background-color: #303035;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    `;

    const SLWrapperAs = styled.main`
    position: relative;
    display:flex;
    text-align: center;

    /* Media Queries */

@media screen and (min-width: 1440px) and (max-width: 3500px) {
  font-size: 65px;
  margin-top:5%;
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  font-size: 39px;
  padding-top:7%;
  margin-top:2%;
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
  font-size: 39px;
  padding-top:4%;
  margin-top:9%;
  margin-bottom:3%

}
@media screen and (min-width: 370px) and (max-width: 631px) {
  font-size: 25px;
  padding-top:9%;
  margin-top:18%;
  margin-bottom:5%

}

@media screen and (max-width:370px) {
  font-size: 25px;
  padding-top:9%;
  margin-top:18%;
  margin-bottom:5%
}
    
    `;


  const DescriptionWrapperAs = styled.main`
  position: relative;
  display:flex;
  text-align: center;
  padding-top: 6px;
  padding-bottom: 54px;
  font-size: 20px;
  margin-left: 5%;
  margin-right: 5%;

  @media screen and (max-width: 631px) {
    font-size: 14px;

  }
  `;


    

  const ModuleWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;

`;



export default function ServiceLine(){


  



    return(




 
              <TopicWrapper>
                <SLWrapperAs>
                      <p className="font-Kanit  ">
                      Empowering Manufacturing Through <span className='text-orange-600'>Smart Factory</span> Solutions.
                      </p>
                    </SLWrapperAs>


  
  




  <DescriptionWrapperAs>
  <div className='text-center text-slate-300 font-EBGaramond'>"Passionate About Industry: Dedicated to Your Success"</div>
  </DescriptionWrapperAs>
  </TopicWrapper>


              





     );
 }
 
 