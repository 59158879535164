import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import "./style.css";

import styled from 'styled-components'
import Logo from './Logo1.js'
import Services from './Page2/Services';
import Product from './Page1/Product';
import Industries from './Page3/Industries';
import Ourcompany from './Page4/Ourcompany';
import Career from './Page5/Career.js';
import Contactus from './Page6/Contactus';
import EventListener from './Page1/EventListener';
import BottomPage from './Bottom';



const Overflow_App = styled.main`
// width: auto;
// height: 100%;

// Overflow-y: hidden;
// Overflow-x: hidden;

`;

export default function App() {
  const [state, setState] = useState({
    isMobileScreen: false,
    selected: null,
    isClicked: false,
  });

  const { isMobileScreen, selected, isClicked } = state;

  const handleResize = () => {
    setState({ ...state, isMobileScreen: window.innerWidth < 962 });
  };

  const handleClick_App = (id) => {
    setState((prevState) => ({
      ...prevState,
      selected: id,
      isClicked: !prevState.isClicked,
    }));
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
   
    <Router>
     
       <div className='  fixed top-0 block w-full h-24 navColor z-50' />
      
      <nav className=' fixed right-2 top-7 font-Montserrat z-50 '>
        
        <div className="flex items-center z-10">
        <Logo handleClick_App = {handleClick_App} />

        
          {isMobileScreen && <MenuButton isClicked={isClicked} handleClick_App={handleClick_App} />}
          {!isMobileScreen && (
            <Breakpoint>
              <Link
                smooth
                to="/Page1"
                className={`${selected === 1 ? "bg-cyan-300" : "bg-transparent text-slate-400"} text-black py-2 px-2 rounded-full mx-3 `}
                onClick={() => handleClick_App(1)}
              >
                Digital Solution
              </Link>
              <Link
                smooth
                to="/Page2"
                className={`${selected === 2 ? "bg-cyan-300" : "bg-transparent text-slate-400"} text-black py-2 px-2 rounded-full mx-3`}
                onClick={() => handleClick_App(2)}
              >
                Services
              </Link>
              <Link
                smooth
                to="/Page3"
                className={`${selected === 3 ? "bg-cyan-300" : "bg-transparent text-slate-400"} text-black py-2 px-2 rounded-full mx-3`}
                onClick={() => handleClick_App(3)}
              >
                Industries
              </Link>
              <Link
                smooth
                to="/Page4"
                className={`${selected === 4 ? "bg-cyan-300" : "bg-transparent text-slate-400"} text-black py-2 px-2 rounded-full mx-3`}
                onClick={() => handleClick_App(4)}
              >
                Our Company
              </Link>
              <Link
                smooth
                to="/Page5"
                className={`${selected === 5 ? "bg-cyan-300" : "bg-transparent text-slate-400"} text-black py-2 px-2 rounded-full mx-3`}
                onClick={() => handleClick_App(5)}
              >
                Career
              </Link>
              <Link
                smooth
                to="/Page6"
                className={`${selected === 6 ? "bg-cyan-300" : "bg-transparent text-slate-400"} hover:bg-slate-200 text-black py-2 px-2 rounded-full mx-3`}
                onClick={() => handleClick_App(6)}
              >
                Contact Us
              </Link>
            </Breakpoint>
          )}
        </div>

        {isClicked && isMobileScreen && (
  <div className='fixed top-0 left-0 w-full h-full bg-[#303036] flex flex-col text-white justify-center items-center text-center font-Montserrat '>
    <Link
      smooth
      to="/Page1"
      className=' w-full py-5 px-5'
      onClick={() => handleClick_App(1)}
    >
      Digital Solution
    </Link>
    <Link
      smooth
      to="/Page2"
      className=' w-full py-5 px-5'
      onClick={() => handleClick_App(2)}
    >
      Services
    </Link>
    <Link
      smooth
      to="/Page3"
      className=' w-full py-5 px-5'
      onClick={() => handleClick_App(3)}
    >
      Industries
    </Link>
    <Link
      smooth
      to="/Page4"
      className=' w-full py-5 px-5'
      onClick={() => handleClick_App(4)}
    >
      Our Company
    </Link>
    <Link
      smooth
      to="/Page5"
      className=' w-full py-5 px-5'
      onClick={() => handleClick_App(5)}
    >
      Career
    </Link>
    <Link
      smooth
      to="/Page6"
      className=' w-full py-5 px-5'
      onClick={() => handleClick_App(6)}
    >
      Contact Us
    </Link>
  
  </div>
)}

      </nav>
     


      <Routes>
        <Route path="/" element={<Product />} />
        <Route path="/Page1" element={<Product />} />
        <Route path="/Page2" element={<Services />} />
        <Route path="/Page3" element={<Industries />} />
        <Route path="/Page4" element={<Ourcompany />} />
        <Route path="/Page5" element={<Career />} />
        <Route path="/Page6" element={<Contactus />} />
      </Routes>
      <BottomPage handleClick_App = {handleClick_App}></BottomPage>
     
    </Router>
    
  );
}

const MenuButton = ({ isClicked, handleClick_App }) => {
  // console.log("MenuButton", isClicked)
  return (
    <div className="absolute right-10 -top-2  z-10">
      <input type="checkbox" id="checkbox" style={{ display: 'none' }} checked={isClicked} onChange={() => {}} />
      <label htmlFor="checkbox" className="toggle" onClick={() => handleClick_App()}>
        <div className="bars" id="bar1"></div>
        <div className="bars" id="bar2"></div>
        <div className="bars" id="bar3"></div>
      </label>
       <EventListener isClickedE={isClicked}></EventListener>
    </div>
  );
};

const Breakpoint = styled.main`
  @media only screen and (max-width: 2000px) {
    font-size: 17px;
    padding-left: 12px;
    padding-right: 12px;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 14px;
    padding-left: 9px;
    padding-right: 9px;
  }
  @media only screen and (max-width: 1062px) {
    font-size: 12px;
    padding-left: 6px;
    padding-right: 6px;
  }



`;