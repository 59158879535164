import { useLocation, useNavigate } from "react-router-dom";


const Logo  = ({ handleClick_App }) =>
{

const location = useLocation();
const navigate = useNavigate();


const clickHandler = (id) => {
  handleClick_App(id);
 if (location.pathname !== '/Page1') {
      navigate('/Page1');
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };



  return (
    <div 
    onClick={() =>clickHandler(1)}
    className="left-positioned scale z-50 ">
      <div className="box-container3 hover:cursor-pointer">
        <div className="gradient-text text-5xl font-bold font-PT tracking-tight inline-block">
          <span className="glow-border pl-2">i</span>
          <span className="glow-border">n</span>
          <span className="glow-border">t</span>
          <span className="glow-border">e</span>
          <span className="glow-border">l</span>
          <span className="glow-border">l</span>
          <span className="glow-border">i</span>
       
       <style >{`
       
       .gradient-text {
        background: linear-gradient(90deg, #0c96f1, #1623db, #d1082d);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
       
       
       
       `}


       </style>

        </div>
        <div className="text-black text-5xl px-[2px] font-Righteous tracking-wide inline-block">
          <span className="glow-border">M</span>
          <span className="glow-border">E</span>
          <span className="glow-border">T</span>
          <span className="glow-border">A</span>
        </div>


        <style >{`

.box-container3 {
  display: inline-block;
  clip-path: polygon(0 0, 100% 0%, 85% 100%, 0 100%);
  padding-right: 40px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #ffffff;
  border: 5px solid #000;
  border-radius: 10px;
  transform-origin: left;
}
`}</style >








      </div>

      <div className="box-container4 absolute">
        <div className="text-white font-Kanit tracking-wide relative left-8 text-xs">Digital Twin</div>

        <div className="flex">
          <div className="font-Kanit tracking-wide text-xs ml-7 text-black">&</div>
          <div className="text-white font-Kanit tracking-wide text-xs ml-2">Smart</div>
        </div>
        <div className="flex">
          <div className="text-white font-Kanit tracking-wide relative left-3 text-xs">Factory </div>
          <div className="text-white font-Kanit tracking-wide relative left-3 text-xs pl-1">Enablers</div>
        </div>

<style >
{`



.box-container4 {
  display: inline-block;
  clip-path: polygon(35% 0, 150% 0, 150% 100%, 5% 100%);
  top: 4.5px;
  left: 265px;
  padding-left: 15px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f15b04;
  border-radius: 10px;
  transform-origin: left;
}



`}



</style>
      </div>

      <style >{`

     

.left-positioned {
    position: fixed;
    top: 1%;
  }
  @media only screen and (min-width: 1200px) {
    .left-positioned {
      left: 10%;
    }}
  @media only screen and (min-width: 856px) and (max-width: 1520px) {
    .left-positioned {
      left: 2%;   
   } }
  @media only screen and (max-width: 856px) {
    .left-positioned {
      left: 2%;
      top: 2%;
      transform: translateX(-50%) translateY(-50%);
    } }




  .scale {
    transform-origin: top left;
  }
  @media only screen and (min-width: 1500px) {
    .scale {
      transform: scale(1.1);
    }}
  @media only screen and (min-width: 780px) and (max-width: 1500px) {
    .scale {
      transform: scale(0.75);
    } }
  @media only screen and (min-width: 330px) and (max-width: 780px) {
    .scale {
      transform: scale(0.65);
    }}
  @media only screen and (max-width: 340px) {
    .scale {
      transform: scale(0.5)
    } }
  






  


   
.glow-border {
  text-stroke: 2px #fff;
  text-shadow: 0 0 50px rgba(255, 255, 255, 0.3), 0 0 20px rgba(255, 255, 255, 0.3),
    0 0 30px rgba(255, 255, 255, 0.2);
}
  

       
          `}</style >
          </div>
        );
      }

      export default Logo;