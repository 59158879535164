export default function OilDrill(){

    return (


<svg className="icon icon-tabler icon-tabler-crystal-ball text-orange-800" width="24" height="24" viewBox="0 0 45.764 45.764" strokeWidth="2" stroke="currentColor" fill=" #b84807" strokeLinecap="round" strokeLinejoin="round">
<g>
	<g>
		<path d="M43.868,39.777h-8.987l-8.494-17.936c0.635-0.771,1.088-1.705,1.297-2.714l5.824-2.095l3.973,6.529    c0.363,0.598,1.098,0.856,1.756,0.621l2.771-0.992c0.66-0.234,1.062-0.903,0.965-1.597l-1.289-9.09    c-0.166-1.164-0.565-2.281-1.176-3.285l-4.771-7.844c-0.363-0.599-1.098-0.859-1.758-0.623l-2.771,0.992    c-0.659,0.235-1.062,0.902-0.963,1.596l1.073,7.568l-5.812,2.084c-1.097-0.899-2.504-1.438-4.039-1.438    c-3.102,0-5.682,2.207-6.234,5.122L4.317,20.59c-1.688,0.605-2.566,2.465-1.962,4.154c0.28,0.78,0.847,1.423,1.583,1.799v13.234    H1.903C0.863,39.777,0,40.572,0,41.612v1.649c0,1.041,0.863,1.837,1.903,1.837h41.965c1.04,0,1.896-0.796,1.896-1.837v-1.649    C45.764,40.572,44.908,39.777,43.868,39.777z M21.469,15.506c1.302,0,2.357,1.046,2.357,2.336s-1.055,2.337-2.357,2.337    c-1.302,0-2.357-1.046-2.357-2.336S20.167,15.506,21.469,15.506z M6.918,39.777V26.562l8.94-3.183L8.094,39.777H6.918z     M14.087,39.777l7.396-15.619c0.003,0,0.005,0.013,0.009,0.013l7.395,15.606H14.087z"/>
	</g>
</g>
</svg>




    );
}

