import React from 'react';
import Android_oil from './Android_oil'
import IOS_oil from './IOS_oil'



const Oil_Industry = () => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);


  if (isIOS) {
    // Render iOS-specific content
    return (
      <IOS_oil></IOS_oil>
    ) ;
  } else {
    // Render content for other devices or browsers
    return (
   <Android_oil></Android_oil>
    );
    
  }
};

export default Oil_Industry;
