import styled from 'styled-components'

const RespoFMCG = styled.main`

@media screen and (min-width: 1440px) and (max-width: 3500px) {
    transform: scale(1,1)
   
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
	transform: scale(1,1)
  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
	transform: scale(1,1)
  }
  
  @media screen and (min-width: 376px) and (max-width: 631px) {
	transform: scale(0.7,0.7)
  }
  
  @media screen and (max-width: 376px) {
	transform: scale(0.5,0.5)
  }
`

export default function FMCG(props){
    const { color } = props;

    return (

<RespoFMCG>
<svg xmlns="http://www.w3.org/2000/svg" fill={color} width="80px" height="80px" viewBox="0 0 16 16">

<g id="Layer_2" data-name="Layer 2">

<g id="Layer_1-2" data-name="Layer 1">

<path d="M14,11.05V8.5a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5V11H6V8.5A.5.5,0,0,0,5.5,8h-3a.5.5,0,0,0-.5.5v2.55A2.5,2.5,0,0,0,2.5,16h11a2.5,2.5,0,0,0,.5-4.95ZM6,12a2.46,2.46,0,0,0,0,3H4.49a2.46,2.46,0,0,0,0-3H6Zm.49,1.5A1.5,1.5,0,1,1,8,15,1.5,1.5,0,0,1,6.5,13.5ZM10,12h1.52a2.46,2.46,0,0,0,0,3H10a2.46,2.46,0,0,0,0-3Zm1-3h2v2H11ZM3,9H5v2H3ZM1,13.5A1.5,1.5,0,1,1,2.5,15,1.5,1.5,0,0,1,1,13.5ZM13.5,15A1.5,1.5,0,1,1,15,13.5,1.5,1.5,0,0,1,13.5,15ZM2,7A.5.5,0,0,0,2,6,1,1,0,1,1,3,5,.5.5,0,0,0,4,5,2,2,0,0,0,2,3V2H6a2,2,0,0,0,4,0h4V3a2,2,0,0,0-2,2,.5.5,0,0,0,1,0,1,1,0,1,1,1,1,.5.5,0,0,0,0,1,2,2,0,0,0,1-3.72V1.5a.5.5,0,0,0-.5-.5H9.72A2,2,0,0,0,6.28,1H1.5a.5.5,0,0,0-.5.5V3.28A2,2,0,0,0,2,7ZM8,1A1,1,0,1,1,7,2,1,1,0,0,1,8,1Z"/>

</g>

</g>

</svg>
</RespoFMCG>
    );
}


