
import React, { useRef, useState } from "react";
import { useGLTF,Html } from "@react-three/drei";
import {useFrame} from '@react-three/fiber';
import * as THREE from 'three';

export default function LoadCell(props) {
  const { nodes, materials } = useGLTF("./LoadCell.glb");








//To incorpate with clicking action
const [selectedMesh, setSelectedMesh] = useState(null)
const [Info, setInfo] = useState(false)


// Actions to perform upon hovering any mesh
const cursorEnter = (event)=> 
{
  document.body.style.cursor = 'pointer'
  
 
}

//Actions to perform upon clicking any mesh
const clickEvent = (event, id) =>
{
  document.body.style.cursor = 'pointer'
//   clickHandler()
  setSelectedMesh(id)
  setInfo(true)
  HealthBar()
  event.stopPropagation()
  
}

//Actions to perform upon when cursor leaves the mesh.
const cursorLeft = (event) =>
{
  document.body.style.cursor = 'default'
  setInfo(false)
}









  const CellRef = useRef()
  useFrame(() =>
{
    CellRef.current.rotation.y += 0.01
    

})





  return (
    
    <group ref={CellRef} {...props} dispose={null}

     onPointerEnter = {cursorEnter}
        onClick = {(event)=> clickEvent(event, 'M1')}
        onPointerOut = {cursorLeft}>

        { selectedMesh === 'M1' && Info && <DiaglogBox name={"Oil Rig Load Cell"} code={"01150398201"}/> }
 

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plunger.geometry}
        material={nodes.Plunger.material}
        position={[0.003, 0.305, 0.009]}
        rotation={[-Math.PI, 1.426, -Math.PI]}
        scale={[0.637, 0.186, 0.637]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.BladderSupport.geometry}
        material={nodes.BladderSupport.material}
        position={[-0.002, 0.38, 0]}
        rotation={[0, 1.333, 0]}
        scale={[0.876, 0.042, 0.876]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Upper_Bell_Bolt.geometry}
        material={nodes.Upper_Bell_Bolt.material}
        position={[0.8, 0.515, -0.126]}
        rotation={[0, 1.333, 0]}
        scale={[0.026, 0.062, 0.026]}
     /> 

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lower_Bell_Bolt.geometry}
        material={nodes.Lower_Bell_Bolt.material}
        position={[0.198, 0.105, -0.769]}
        rotation={[-3.142, -1.333, 0]}
        scale={[0.026, 0.062, 0.026]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Upper_Bell_Nut_Support.geometry}
        material={nodes.Upper_Bell_Nut_Support.material}
        position={[-0.815, 0.357, 0.107]}
        rotation={[0, 1.333, 0]}
        scale={[0.06, 0.16, 0.06]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lower_Belll_Nut_Support.geometry}
        material={nodes.Lower_Belll_Nut_Support.material}
        position={[-0.694, 0.268, 0.44]}
        rotation={[0, 1.333, 0]}
        scale={[0.06, 0.16, 0.06]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Upper_bell.geometry}
        material={nodes.Upper_bell.material}
        position={[0.002, 0.548, 0.009]}
        rotation={[0, 1.117, 0]}
        scale={[0.926, 0.052, 0.926]}
      >
        </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lower_Bell.geometry}
        material={nodes.Lower_Bell.material}
        position={[0.003, 0.069, 0.009]}
        rotation={[-3.142, -1.494, 0]}
        scale={[0.926, 0.052, 0.926]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.CheckValve1.geometry}
        material={nodes.CheckValve1.material}
        position={[-0.416, 0.865, -0.013]}
        rotation={[0, 0, 0.713]}
        scale={[0.133, 0.026, 0.133]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.CheckValve1_head_nut1.geometry}
        material={nodes.CheckValve1_head_nut1.material}
        position={[-0.467, 0.925, 0.244]}
        rotation={[Math.PI / 2, 0.713, 0]}
        scale={[0.028, 0.04, 0.028]}
      >        


            
            </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.CheckValve1_head_nut_2.geometry}
        material={nodes.CheckValve1_head_nut_2.material}
        position={[-0.468, 0.926, -0.28]}
        rotation={[Math.PI / 2, 0.713, 0]}
        scale={[0.056, 0.033, 0.056]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.CheckValve_bolt2.geometry}
        material={nodes.CheckValve_bolt2.material}
        position={[-0.349, 0.936, -0.013]}
        rotation={[0, 0, 0.713]}
        scale={[0.011, 0.019, 0.011]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.CheckValve_bolt1.geometry}
        material={nodes.CheckValve_bolt1.material}
        position={[-0.495, 0.809, -0.013]}
        rotation={[0, 0, 0.713]}
        scale={[0.011, 0.019, 0.011]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.InnerBell001.geometry}
        material={nodes.InnerBell001.material}
        position={[-0.002, 0.475, 0.008]}
        rotation={[0, 1.33, 0]}
        scale={[1, 0.044, 1]}
      />
    </group>
  );
}




const DiaglogBox  = (props) => (
  <Html {...props} >  
              <div className=" health_bar bg-gradient-to-r from-gray-700 to-gray-900 rounded-lg h-auto w-80 text-white px-4 py-3">

                  <div className=" text-teal-300 py-2">Health Remaining - </div>
                    <div className="w-full bg-gray-200 rounded-full">
                      <div className="bg-red-600 text-s font-medium text-blue-100 text-center p-0.5 leading-none rounded-l-full" style={{width: "24.6%"}}> 24.6% </div>
                  </div>

                  <ul className="py-3 px-3 list-disc">
                  <li className="  py-[2px] anomaly_bar inline-flex text-red-500">Anomaly Detected on -<div className="text-red-500 "> 2/3/2023</div> </li>
                      <li className=" py-[2px] Part_bar">Part Name - {props.name}</li>
                      
                      <li className="  py-[2px] Part_bar">OEM Part-Code - {props.code}</li>
                      <li className="  py-[2px] Part_bar">Last changed on 25-Jan-2021 </li>
                      
                      <li className="  py-[2px] Part_bar inline-flex">Total Run Time - <div className="text-teal-300 font-bold">3016 Hours</div> </li>
                      <div><li className="  py-[2px] Part_bar inline-flex">Production Run Time - <div className="text-teal-300 font-bold">2495 Hours</div> </li></div>
                      <li className="  py-[2px] Part_bar inline-flex">RFID Location  - <div className="text-teal-300 font-bold">R1-CX-72</div>  </li>

                  </ul>
                      
                      
                </div>     
  </Html>
  

)

const HealthBar = () => (
  <Html>

</Html>
)







useGLTF.preload("/LoadCell.glb");



