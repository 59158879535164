import styled from 'styled-components';






const ContactLineContainer = styled.div`
position: relative;

`;

const TopicWrapper = styled.main`
position: relative;


flex-direction: column;
text-align: center; /* Center content horizontally */
z-index: 2;

  /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    font-size: 65px;
  }

  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    font-size: 35px;
  }

  @media screen and (min-width: 631px) and (max-width: 1000px) {
    font-size: 28px;
  }

  @media screen and (max-width: 631px) {
    font-size: 45px;
    padding-bottom:17px;
  }
`;

const AddressWrapper = styled.main`
  position: relative;
 
  
  text-align: center; /* Center content horizontally */
  z-index: 2;
  /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    font-size: 18px;
    margin-bottom:10%;
  }
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    font-size: 15px;
    margin-bottom:10%;
  }
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    font-size: 15px;
    text-align: center;
    margin-bottom:10%;
  }
  @media screen and (max-width: 631px) {
    font-size: 15px;
    text-align: center;
    margin-bottom:10%;
  }

  
`;
const MessageWrapper = styled.main`
position: relative;
flex-direction: column;
text-align: center; 

z-index: 2;

/* Media Queries */
@media screen and (min-width: 1440px) and (max-width: 3500px) {
  font-size: 18px;
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  font-size: 15px;
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
  font-size: 15px;
  text-align: center;
}

@media screen and (max-width: 631px) {
  font-size: 15px;
  text-align: center;
}`;













export default function ContactLine() {




  return (
    <ContactLineContainer>
      <TopicWrapper>
        <div className='text-white font-Kanit'> Lets Connect !! </div>
      </TopicWrapper>
      <AddressWrapper>
        <div className='font-Montserrat text-gray-300'><span className="text-orange-600">Office: </span>HD-235</div>
        <div className='font-Montserrat text-gray-300'>WeWork Forum Building,</div>
        <div className='font-Montserrat text-gray-300'>DLF Cybercity, DLF Phase III, Gurugram,</div>
        <div className='font-Montserrat text-gray-300'>Haryana, India (122022)</div>
        <div href='tel:+918923323099' className='font-Montserrat text-gray-300 pt-5'><span className="text-orange-600">Phone: </span>+91-8923323099</div>
        <div href='mailto:info@intellimeta.ai' className='font-Montserrat text-gray-300 pt-5'><span className="text-orange-600">Email: </span>info@intellimeta.ai</div>
      </AddressWrapper>
<MessageWrapper><div className='font-Montserrat'> We look forward to address your Industrial Automation needs through our comprehensive Field Engineering services, while empowering you with state-of-the-art digital technologies. </div>
  
  <div className='py-5'> Kindly complete the form, and we will promptly arrange a meeting to discuss further.</div>
  </MessageWrapper>
    </ContactLineContainer>
  );
}
