import React, { useRef } from 'react';
import { useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import Ground from './Ground_pg1.js';
import { OrbitControls } from '@react-three/drei';
import Notification from './Notification.js';
import { PerspectiveCamera } from '@react-three/drei'
import LoadCell from './LoadCell.js';
import EventListener from './EventListener.js';


















function Canvas_Product() {
  

  const [isCameraRotating, setCameraRotating] = useState(false);
  const [isMeshVisible, setMeshVisible] = useState(false);
 
  const cameraRef = useRef();
  const LC = useRef()


  const handleChildStateChange = () => {
    // console.log("sucessfully invoked")
    setMeshVisible(true)
  
  };

  const HandleHide = () => {

    console.log("sucessfully invoked from APP")
  }








  return (
   
      <Canvas
      style={{ background: '#303036' }}

>
{/* <CameraRotation cameraRef={cameraRef} isRotating={isCameraRotating}  /> */}
<PerspectiveCamera ref={cameraRef}  makeDefault fov={80} position={[0, 2, 5.39]} near={.1} far={150} resolution={1024}/>
<OrbitControls
        enablePan={false} // Disable panning
        minDistance={2} maxDistance={10}
        // enableZoom={false} // Disable zooming
        maxPolarAngle={Math.PI / 2}
      />
     
      <Ground />
      <directionalLight position={ [ 3, 3, 3 ] } intensity={ 1 } />
      <ambientLight intensity={ 0.5 } /> 
      <Notification
      onStateChange={handleChildStateChange}
      rotation={[Math.PI*1.91  , Math.PI * 2, 0]} position={[-4, -4.5, 3]} >
     
      </Notification>
      <EventListener  
      onStateChange1 ={HandleHide}>

      </EventListener>




      <LoadCell visible={isMeshVisible} position={[0, 1, -2]} scale={2.3}/>




    </Canvas>
  );
}

export default Canvas_Product;
