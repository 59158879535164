import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

export default function EventListener({  onStateChange1, ...props }) {
    // console.log("Event Lister is called")
    const { isClickedE } = props;
 
    if (isClickedE) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
 // Arrow function to be executed when isClicked is true
const ArrowFunction = () => {
    onStateChange1(true);
    // console.log("inside Event Listener")
  };

  return (
   <>
   </>
  );
}

