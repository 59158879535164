import './Cu.css';
import ContactUs from './NewForm.js'
import ContactLine from './ContactLine';

import styled, { keyframes } from 'styled-components';

// Component 1
const Component1 = styled.div`


  top:0;







  /* * Media Queries */ 
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    {
      margin:5%;
      transform: scale(1);
    }
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    {
      margin:5%;
      transform: scale(0.7);
    }
  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    {
     transform: scale(0.7);
    }
  }
  
  @media screen and (min-width: 376px) and (max-width: 631px) {
    {
      margin-top: -20%;
     transform: scale(0.6);
    }
  }
  
  @media screen and (max-width: 376px) {
    {
      margin-top: -20%;
     transform: scale(0.6);
    }
  }
  





`;

// Component 2
const Component2 = styled.div`

  




  /* * Media Queries */ 
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    {
      margin:5%;
      transform: scale(1);
    }
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    {
      margin:5%;
      transform: scale(0.7);
    }
  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    {
     transform: scale(0.7);
    }
  }
  
  @media screen and (min-width: 376px) and (max-width: 631px) {
    {
      margin-top: -50%;
     transform: scale(0.6);
    }
  }
  
  @media screen and (max-width: 376px) {
    {
      margin-top: -45%;
     transform: scale(0.6);
     display: flex;
     justify-content: center;
     align-items: center;
     text-align: center;
    }
  }




`;

// Container for the components
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100vh;
  z-index:3;

  
  /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    flex-direction: row;
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    flex-direction: row;

  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    width:100vw;

    
  }
  
  @media screen and (max-width: 631px) {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    width:100vw;

  
`;


export default function Image_Content()
{



    return(





            <Container>


                        <Component1>
                                      <ContactUs/>
                        </Component1>




                        <Component2>
                                      <ContactLine/>
                        </Component2>



            </Container>

      

    );

}



