import React, { useLayoutEffect } from 'react'

import styled from 'styled-components'
import Search from '../svgs/Search.js'
import Database from '../svgs/Database.js'
import RFID from '../svgs/RFID.js'
import Down from '../svgs/Down.js'
import Health from '../svgs/Health.js'






export default function Module4Card() {
  

  return (
    

<div>
    <ModuleHeading className='text-3xl font-bold font-Montserrat tracking-tight'> Inventory Management - Manage Spareparts in 3D!!  </ModuleHeading>

    
        <ModuleWrapper>

                       

{/* Module 1 - Intelliscope -  Remote Asset Monitoring -----------------------------------------------------------------------*/}

                                <div>
                                <div className='ModuleCard'> 
                        <div className='relative py-4 text-center '> Inventory Management</div>
                    <div>
                    <div className="font-Montserrat text-xs text-black p-2 flex items-center">
                                                      <span className="ml-2 text-amber-500"><Search/></span>
                                                      <span className="ml-2">Quick partcode search </span>
                                                    </div>
                                                    <div className="font-Montserrat text-xs text-black p-2 flex items-center">
                                                      <span className="ml-2 text-fuchsia-600"><Database/></span>
                                                      <span className="ml-2">Spare Part historical information</span>
                                                    </div>
                                                    <div className="font-Montserrat text-xs text-black p-2 flex items-center">
                                                      <span className="ml-2"><RFID/></span>
                                                      <span className="ml-2">Part location RFID tracking </span>
                                                    </div>
                                                    <div className="font-Montserrat text-xs text-black p-2 flex items-center">
                                                      <span className="ml-2"><Down/></span>
                                                      <span className="ml-2">Reduction in MTTR </span>
                                                    </div>
                                                    <div className="font-Montserrat text-xs text-black p-2 flex items-center">
                                                      <span className="ml-2"><Health/></span>
                                                      <span className="ml-2">Spare part health prediction</span>
                                                    </div>
                                                              </div>
                                    </div>
                                </div>    

{/*SLASH Module 1 - Intelliscope -  Remote Asset Monitoring -----------------------------------------------------------------------*/}


                                <div>                                
                                      {/* <div className="vertical-line"></div> */}

                                      <div>
                                        <ModuleContent className=' text-slate-700'>
                                        <VerticalL4 className='text-white'>.</VerticalL4>
                                        Our feature for searching by partcode expedites the identification and retrieval of spare parts, thereby expediting maintenance operations. Access historical data on spare parts in order to make informed decisions and ensure optimal performance. Utilise inventory location RFID tracking for real-time stock level visibility and accurate inventory management. Experiencing a significant reduction in mean time to repair (MTTR) thanks to the seamless availability of spare parts.  In addition, monitor the condition of spare parts to proactively address maintenance requirements and optimise performance.
   </ModuleContent></div>
                                        
                                        
                                      
                                  </div>      

          </ModuleWrapper>
    </div>
  
  )
}

const ModuleHeading = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
  background: linear-gradient(174deg, #0c96f1, #1623db, #d1082d);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            /* Media Queries */
            @media screen and (min-width: 1440px) and (max-width: 3500px) {
              padding-top: 1%;
              padding-bottom: 1%;
            }
            
            @media screen and (min-width: 1000px) and (max-width: 1440px) {
              padding-top: 1%;
              padding-bottom: 2%;
            }
            
            @media screen and (min-width: 631px) and (max-width: 1000px) {
              padding-top: 5%;
              padding-bottom: 5%;
            }
            
            @media screen and (max-width: 631px) {
              padding-top: 5%;
              padding-bottom: 5%;
            }
  
`;
const ModuleWrapper = styled.main`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  

  /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    flex-direction: row;
    text-align: left;
    margin-left: 5%;
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    flex-direction: row;
    margin-left: 5%;
  
  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    flex-direction: column;
   
  }
  
  @media screen and (max-width: 631px) {
    flex-direction: column;
    
  }
  
`;




const ModuleContent = styled.main`
  position: relative;
  display: flex;
  align-items: center;
  width:90%;

 

  /* Media Queries */

  @media screen and (min-width: 2001px) {
    font-size: 23px;

  @media screen and (min-width: 1440px) and (max-width: 2000px) {
    font-size: 23px;
    padding-right: 15%;
  }


  @media screen and (min-width: 1440px) and (max-width: 2000px) {
    font-size: 23px;
    padding-right: 15%;
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    font-size: 20px;
  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    font-size: 18px;
    margin-left:5%; 
  }
  
  @media screen and (max-width: 631px) {
    padding-top:15%;
    margin:10%;
    font-size: 8px;
  
`;

const VerticalL4 = styled.main`
 {
width: 5px;
background-color: #fb8c00; 
margin-left:5%;
margin-right:5%;

@media screen and (min-width: 1440px) and (max-width: 3500px) {
  height: 255px;
}
@media screen and (min-width: 1000px) and (max-width: 1440px) {
  height: 255px;
}
@media screen and (min-width: 631px) and (max-width: 1000px) {
  height: 255px;
}
@media screen and (min-width: 450px) and (max-width: 631px) {
  height: 355px;
}
@media screen and (min-width: 400px) and (max-width: 450px) {
  height: 380px;
}
@media screen and (max-width: 400px) {
  height: 405px;


`








