import React, { useLayoutEffect } from 'react'

import styled from 'styled-components'
import Canvas_Oil from './Canvas_Oil.js'

import Colab from '../svgs/Colab.js';

import FMCG_Data from '../svgs/FMCG_Data.js'

import Oil_Anamoly from '../svgs/Oil_Anamoly.js';
import Oil_ASL from '../svgs/Oil_ASL.js';
import Oil_Safety from '../svgs/Oil_Safety.js';

import FMCG_Regulator from '../svgs/FMCG_Regulator.js';

export default function Android_oil() {


  return (
    
    
       <Main1>

          <Main >
              
              
                  <CanvasContainer>
                                      <Canvas_Oil/>
                    </CanvasContainer>

                    <ContentContainer>
                      <Content>
                      
                                      <h1 className='font-Kanit text-slate-900 pt-3'> Embark on a digitalization journey to enhance productivity and safety by using data-driven insights and maximize asset performance.</h1>
                                      <SvgGrpWrapper>
                                      <h1><SvgGrp1Wrapper><Colab></Colab><div className='text-black pl-5 pt-5'>Predictive Analytics</div></SvgGrp1Wrapper>
                                           <SvgGrp1Wrapper><Oil_Anamoly></Oil_Anamoly><div className='text-black pl-5 pt-5'>Process Anomaly detection</div></SvgGrp1Wrapper> 
                                           <SvgGrp1Wrapper><FMCG_Data></FMCG_Data><div className='text-black pl-5 pt-5'> Improved Efficiency</div></SvgGrp1Wrapper></h1>
                                      <h1>
                                        <SvgGrp2Wrapper><FMCG_Regulator></FMCG_Regulator><div className='text-black pl-5 pt-5'>Regulatory Compliance</div></SvgGrp2Wrapper>
                                         <SvgGrp2Wrapper><Oil_Safety></Oil_Safety><div className='text-black pl-5 pt-5'>Safety Adherence</div></SvgGrp2Wrapper>
                                        <SvgGrp2Wrapper><Oil_ASL></Oil_ASL><div className='text-black pl-5 pt-5'>Asset Life Extension</div></SvgGrp2Wrapper>
                                      </h1>
                                      </SvgGrpWrapper>
                                      <h1 className='font-EBGaramond text-slate-700 py-4'>Managing the high stakes associated with terminals, refineries, rigs, platforms, and pipelines poses a significant challenge, requiring tight control over their life-cycle. Laveraging CloudLine smart solution you can accelerate productivity, extend asset lifecycles, and reduce maintenance expenses </h1>

                      </Content>
                                       

                    </ContentContainer>
                    
                   
            </Main>

       
            </Main1>

                         

            

  
  )
}




const BottomWrapper1 = styled.div`
position:relative;

transform: scale(1.12);
margin-top:20%;`




const Main1 = styled.main`
position: relative;
display: flex;
flex-direction: column;
padding-top :0%;
width: 100%;
height: auto;
 
`

const Main = styled.main`
position: relative;
display: flex;
flex-direction: row;
width: 100%;
height:auto;

@media only screen and (max-width: 1400px) {
  flex-direction: column;
  
  }



`




const CanvasContainer = styled.div`
position: relative;
flex: 1 1 0;
min-width: 0;

padding-top: 3em;
padding-right: 0em;

/* * Media Queries */ 
@media screen and (min-width: 1440px) and (max-width: 3500px) {
  {
    min-height: 500px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  {
    min-height: 500px;
    order: -1;
  }
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
  {
    min-height: 500px;
    order: -1;
  }
}

@media screen and (min-width: 376px) and (max-width: 631px) {
  {
    min-height: 350px;
    order: -1;
  }
}

@media screen and (max-width: 376px) {
  {
    order: -1;
    min-height: 350px;
  }
}

`

const ContentContainer = styled.div`
position: relative;
flex: 1 1 0;
  min-width: 0;
  min-height: 500;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  @media only screen and (max-width: 1200px) {
    margin-top: 0;
  }
`
const Content = styled.div`
  flex: 1;
  padding-left: 4em;

  h2 {
    // color: #f7057e;
    font-size: 2rem;
    margin-top: 0.5em;
    padding: 0;
    line-height: 0;
    margin-bottom: 0em;
    white-space: nowrap;
  }

  h3 {
    float: right;
    text-align: right;
    width: 100px;
    font-size: 0.8rem;
  }

  h1 {
    font-size: 1.6rem;
  
    
  }

  p {
    font-size: 0.8rem;
    width: 200px;
  }

  @media only screen and (max-width: 1200px) {
    width: 100%;
    padding-right: 2em;
    padding-left: 2em;
    h1 {
      font-size: 1.6rem;
      line-height: 2rem;
    }

    h2 {
      font-size: 2.3rem;
      line-height: 2.3rem;
      margin-bottom: 0.8rem;
    }
  }

  @media only screen and (max-width: 1000px) {
    h1 {
      font-size: 1.6rem;
      line-height: 2rem;
    }

    h2 {
      font-size: 1.6rem;
      line-height: 1.6rem;
    }
  }

  @media only screen and (max-width: 600px) {
    h1 {
      font-size: 1.3rem;
      line-height: 1.8rem;
      padding-bottom: 1.5em;
    }

    h2 {
      font-size: 1.3rem;
      line-height: 1.3rem;
    }
  }
`

const SvgGrpWrapper = styled.main`
position: relative;
display: flex;
flex-direction: row;
padding-top :0.5rem;
width: 100%;
height: auto;

@media screen and (max-width: 600px)
 {
  flex-direction: column;
  }
}
`;

const SvgGrp1Wrapper = styled.main`
position: relative;
display: flex;
flex-direction: row;
padding-top :10%;
padding-bottom :10%;
padding-right :3em;


`;
const SvgGrp2Wrapper = styled.main`
position: relative;
display: flex;
flex-direction: row;
padding-top :10%;
padding-bottom :10%;
padding-left :3em;

@media screen and (max-width: 600px)
 {
  padding-left :0em;;
  }
}

`;