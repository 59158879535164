import React, { useState } from 'react';
import styled from 'styled-components'
import BottomPage from '../Bottom';


import FMCG from '../svgs/FMCG';
import Oil from '../svgs/Oil';
import FMCG_Industry from './FMCG_Industry';
import Oil_Industry from './Oil_Industry';
import './reactslider.css';

const TopicWrapper = styled.main`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    

    /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    font-size: 35px;
    padding-top:30px;
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    font-size: 35px;
    padding-top:30px;
  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    padding-top:20px;
    font-size: 22px
  }
  
  @media screen and (max-width: 631px) {
    padding-top:90px;
    font-size: 18px
  }
    `;


const Industries_Section1 = () => {
const [currentIndex, setCurrentIndex] = useState(0);
const [colorFMCG, setColorFMCG] = useState('#808080');
const [colorOil, setColorOil] = useState('#ffffff');

const handleColorToggle = (itemIndex) => {
  if (itemIndex === 0) {
    setColorFMCG('#808080');
    setColorOil('#ffffff');
  } else if (itemIndex === 1) {
    setColorFMCG('#ffffff');
    setColorOil('#808080');
  }
};


const Components = [
  { id: 1, content: <div> <Oil_Industry/>  </div>, name: 'Oil & Gas' },
  { id: 2, content: <div> <FMCG_Industry/>  </div>, name: 'FMCG' }
];

const handlerClick = (itemIndex) => {
  setCurrentIndex(itemIndex);
  handleColorToggle(itemIndex); // Moved the handleColorToggle call here

};

return (
    <>
      <div className='slider'>
        {Components.map((itemContent, index) => {
          const isActive = index === currentIndex;
          const className = `slider-item ${isActive ? 'active' : ''}`;
          return (
           
            
            <div
              className={className}
              key={itemContent.id}
            >
              {itemContent.content}
            </div>
          );
        })}
         
        <div className='slider-controls'>
<TopicWrapper><div className="font-Kanit ">Our ready to serve <span className="text-orange-600">Industrial Sectors.</span></div>
        
          <div>
          <div className='slider-indicator'>
            {Components.map((itemContent, index) => (
              <div
                key={index}
                onClick={() => {
                  handlerClick(index);
                  handleColorToggle();
                }}
                className={`slider-indicator-item ${index === currentIndex ? 'active' : ''}`}
              >
                {index === 0 && (
                  <>
                  <Oil color={colorOil} />
                    
                    <span className="indicator-name">{itemContent.name}</span>
                  </>
                )}
                {index === 1 && (
                  <>
                    <FMCG color={colorFMCG}/>
                    <span className="indicator-name">{itemContent.name}</span>
                  </>
                )}
              </div>
            ))}
          </div>
          </div>
          </TopicWrapper>
         
        
        </div>

      </div>
      
    </>
  );
};

export default Industries_Section1;
