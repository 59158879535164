import styled from 'styled-components'
import ModuleLine from './ModuleLine';
import Module1Card from "./Module1Card";
import Module2Card from "./Module2Card";
import Module3Card from "./Module3Card";
import Module4Card from "./Module4Card";
import Module5Card from "./Module5Card";


const Section3Wrapper = styled.main`
margin-bottom: 5%;
`;



export default function Product_Section3()



{
  return(
    <div>








<Section3Wrapper>
  <ModuleLine></ModuleLine>

<Module1Card></Module1Card>
<div className="horizontal-line"></div>

<Module2Card></Module2Card>
<div className="horizontal-line"></div>

<Module3Card></Module3Card>
<div className="horizontal-line"></div>

<Module4Card></Module4Card>
<div className="horizontal-line"></div>

<Module5Card></Module5Card>
</Section3Wrapper>


    </div>

  );
}