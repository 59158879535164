import CompanyLine from "./CompanyLine";
import BottomPage from "../Bottom";
import './AboutUs.css';
import Image_As from "./Image_As";
import styled from "styled-components";
import { useEffect } from 'react';
import ScrollToTop1 from '../Utilities/ScrollToTop1';




import React from 'react';


const Overflow_Oc = styled.main`
// width: auto;
// height: 100%;

Overflow-y: hidden;
Overflow-x: hidden;

`;


export default function OurCompany() {

// Automatically scroll to the top of the page - 

useEffect(() => {
  window.scrollTo(0, 0);
}, []);

 

  return (
<Overflow_Oc>
  <CompanyLine/>

    <Image_As/>

  {/* <BottomPage></BottomPage> */}
  <ScrollToTop1/>
</Overflow_Oc>
    
  );
}
