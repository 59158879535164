
import styled from 'styled-components'
import Forecast_CL from '../svgs/Forecast_CL.js';
import RemoteMonitoring from '../svgs/RemoteMonitoring';
import Energy from '../svgs/Energy';
import InventoryOpti from '../svgs/InventoryOpti';
import Dashboard from '../svgs/Dashboard';
import ThreeD from '../svgs/Navigation.js';
import Game from '../svgs/Game.js';
import Locate from '../svgs/Locate.js';
import LogicalCons from '../svgs/LogicalCons.js';




const ItemWrapper = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; 
 

  `;

  const ModuleWrapper = styled.div`
  position: relative;
  display: flex;
 


  /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    font-size: 15px;
    flex-direction: row;
    align-items: center;
  }

  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    font-size: 15px;
    flex-direction: row;
    align-items: center;
  }

  @media screen and (min-width: 631px) and (max-width: 1000px) {
    font-size: 15px;
  }

  @media screen and (max-width: 631px) {
    padding-top: 10px;
    font-size: 15px;
    
  }
`;

  const SvgWrapper = styled.main`
  padding-bottom: 10px;
  /* Media Queries */
@media screen and (min-width: 1440px) and (max-width: 3500px) {
  transform: scale(2.5);
  padding-top:20px;
  
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  transform: scale(2.5);
  padding-top:20px;
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
  transform: scale(2.5);
  padding-top:10px;
}

@media screen and (max-width: 631px) {
  transform: scale(2.5);
  padding-top:60px;
  padding-bottom:30px;
  
}
  `;

  const TopicWrapper = styled.main`
  
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Add align-items property to center horizontally and vertically */


  /* Media Queries */
@media screen and (min-width: 1440px) and (max-width: 3500px) {
  font-size: 35px;
  padding-top:30px;
  text-align: center;
 
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  font-size: 35px;
  padding-top:30px;
  text-align: center;
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
  padding-top:20px;
  font-size: 22px;
  text-align: center;
}

@media screen and (max-width: 631px) {
  padding-top:10px;
  padding-bottom:15px;
  font-size: 20px;
  text-align: center;

  
}
  `;




  const FeaturesWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  
  /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    transform: scale(0.8);
    flex-direction: row;
    align-items: center;
    padding-top: 30px;
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    transform: scale(0.8);
    flex-direction: row;
  align-items: center;
  padding-top: 30px;
  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    transform: scale(0.8);
    flex-direction: row;
  align-items: center;
  padding-top: 20px;
  }
  
  @media screen and (max-width: 631px) {
    transform: scale(0.5);
    flex-direction: column;
    align-items: center;
    margin-top: -150px;
    
  }
  
  
  `;
  



  const FeatureWrapper = styled.div`

/* Media Queries */
@media screen and (min-width: 1440px) and (max-width: 3500px) {
  font-size: 30px;
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  font-size: 20px;
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
  font-size: 15px;
}

@media screen and (max-width: 631px) {
  font-size: 25px;
  
}


`;

  const DescriptionWrapper = styled.main`
  /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    font-size: 19px;
  }

  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    font-size: 15px;
  }

  @media screen and (min-width: 631px) and (max-width: 1000px) {
    font-size: 15px;
  }

  @media screen and (max-width: 631px) {
    font-size: 20px;
    
  }
  `;





export default function CloudLine(){


  

  


    return(



<div className="CL_background">


              <div className="CL_response"> 
              <TopicWrapper>
                      <p className="font-Kanit text-center  ">
                      Open the gateway to boost productivity through seemless <span className="text-orange-600">IT/OT integration.</span> 
                      </p>
                      </TopicWrapper>

<ModuleWrapper className=' flex flex-wrap'>
<div className=' px-4 font-EBGaramond text-slate-400 inline-flex py-1'><LogicalCons></LogicalCons> Logical Construct</div>
<div className=' px-4 font-EBGaramond text-slate-400 inline-flex py-1'><Forecast_CL></Forecast_CL> Breakdown Prediction </div>
<div className=' px-4 font-EBGaramond text-slate-400 inline-flex py-1'><RemoteMonitoring></RemoteMonitoring>Remote Monitoirng</div>
<div className=' px-4 font-EBGaramond text-slate-400 inline-flex py-1'><Energy></Energy>Energy Optimization</div>
<div className=' px-4 font-EBGaramond text-slate-400 inline-flex py-1'><Dashboard></Dashboard> Factory KPIs Dashboard</div>
<div className=' px-4 font-EBGaramond text-slate-400 inline-flex py-1'><InventoryOpti></InventoryOpti> Inventory Optimization</div>
</ModuleWrapper>

<FeaturesWrapper>    

<ItemWrapper>
<SvgWrapper>
  <ThreeD></ThreeD>
  </SvgWrapper>
  <FeatureWrapper>
  <div className='text-center font-Montserrat py-5  text-slate-50 '>3D Plant Monitoirng</div>
  </FeatureWrapper>
  <DescriptionWrapper>
  <div className='text-center text-slate-300 '>Visualize in real-time and optimize operations like never before, providing actionable insights for enhanced efficiency and productivity</div>
  </DescriptionWrapper>
 </ItemWrapper>

  
  <ItemWrapper>
  <SvgWrapper>
    <Game></Game>
    </SvgWrapper>
    <FeatureWrapper>
  <div className='text-center font-Montserrat py-5  text-slate-50'>Gamification</div>
  </FeatureWrapper>
  <DescriptionWrapper>
  <div className='text-center text-slate-300'> Boost operator efficiency through MTTR gamification, turning downtime into an opportunity for increased productivity and performance.</div>
  </DescriptionWrapper>
  </ItemWrapper>


  <ItemWrapper>
  <SvgWrapper>
    <Locate></Locate>
    </SvgWrapper>
    <FeatureWrapper>
  <div className='text-center font-Montserrat py-5  text-slate-50'>RFID Localization</div>
  </FeatureWrapper>
  <DescriptionWrapper>
  <div className='text-center text-slate-300'>Unleash the power of 3D technology to streamline spare part tracking and inventory localization, eliminating delays and maximizing productivity</div>
  </DescriptionWrapper>
  </ItemWrapper>
</FeaturesWrapper> 

              </div>
              






           
 <style >{`

.CL_background{
  position: relative;
  background-color: #303035;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

}

 .CL_response {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  
  padding-bottom: 0px;


     
     
   
     
   }
   
       @media screen and (min-width: 1440px) and (max-width: 3500px) {
        .CL_response {

          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
      
      @media screen and (min-width: 1000px) and (max-width: 1440px) {
        .CL_response {

          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
      
      @media screen and (min-width: 631px) and (max-width: 1000px) {
        .CL_response {

          display: flex;
          flex-direction: column;
      
          
        }
      }
      
      
      @media screen and (max-width: 631px) {
        .CL_response {
 
 
          display: flex;
          flex-direction: column;
          
         
          
        }
      }
      
 
 
 
 
 
 
 
  `}</style>
 </div>
     );
 }
 
 