
export default function LogicalCons(){

    return (


<svg className="icon icon-tabler icon-tabler-message-2-code text-orange-700" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M8 9h8"></path>
   <path d="M8 13h6"></path>
   <path d="M12 21l-1 -1l-2 -2h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v6"></path>
   <path d="M20 21l2 -2l-2 -2"></path>
   <path d="M17 17l-2 2l2 2"></path>
</svg>





    );
}














