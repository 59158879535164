
import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

const NameWrapper = styled.div`

display: flex;
justify-content: center;
  align-items: center;

flex-direction: row;
`

const CareerForm = () => {
  const [file, setFile] = useState(null);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [option, setOption] = useState('');
  const [role, setRole] = useState('');
  const [title, setTitle] = useState('');


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };


  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOptionChange = (e) => {
    setOption(e.target.value);
  };


  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    

    // Logic to send the file
    // You can perform further processing or send the file to your backend server

    // Reset file state
    setFile(null);
  };


  const Roles = [

    'AI/ML Engineer',
    'NLP - AI Platform Engineer',
    'CAD Designer',
    'Electrical & Automation Service Engineer',
    'Mechanical Field Service Engineer',
    'React.js Developer',
    'React Native Developer',
    'PLC Programer',
   
  ];

  return (
    <div className='bg-white mt-16 mb-16 rounded-lg'>
      <h1 className='text-black pt-5 m-5 font-bold '>Apply Here!!</h1>
      <form onSubmit={handleSubmit}>


  {/* _____________________________________________________________________________________________________ */}

<NameWrapper>
  
  <div className='font-EBGaramond text-slate-700'>
          <label htmlFor="firstName">First Name:</label>
		  <div >
          <input
            type="text"
            id="firstName"
            placeholder="First Name"
            value={firstName}
            onChange={handleFirstNameChange}
            required
            className="border-b border-gray-300 focus:border-blue-500 outline-none text-center "
      
          />
          </div>
        </div>


  {/* _____________________________________________________________________________________________________ */}



  <div className='font-EBGaramond text-slate-700 ml-8 text-center'>
          <label htmlFor="lastName">Last Name:</label>
		  <div >
          <input
            type="text"
            id="lastName"
            placeholder="Last Name"
            value={lastName}
            onChange={handleLastNameChange}
            required
            className="border-b border-gray-300 focus:border-blue-500 outline-none text-center "
          />
          </div>
          </div>
        </NameWrapper>





      





{/* _______________________________________________________________________________________________ */}

<div className='font-EBGaramond text-slate-700 ml-8 text-center mt-5'>
		
    <label htmlFor="email">Email:</label>
<div >
    <input
      type="email"
      id="email"
      placeholder="Email Address"
      value={email}
      onChange={handleEmailChange}
      required
      className="border-b w-[50%] border-gray-300 focus:border-blue-500 outline-none text-center "
    />

    </div>
  </div>


  
  {/* _____________________________________________________________________________________________________ */}



  <div className='font-EBGaramond text-slate-700 ml-8 text-center mt-5'>
  <NameWrapper >
          <label>Looking For:</label>
          <label>
            <div className='ml-5'>
            <input
              type="radio"
              value=" Internship"
              checked={option === ' Internship'}
              onChange={handleOptionChange}
              
            
              />  Internship
            </div>
            
          </label>
          <label>
            <div className='ml-5' >
            <input
              type="radio"
              value="Full Time Role"
              checked={option === 'Full Time Role'}
              onChange={handleOptionChange}
            /> Full Time Role
            </div>
            
          </label>
          </NameWrapper>
        </div>

  {/* _____________________________________________________________________________________________________ */}






  {/* _____________________________________________________________________________________________________ */}







  <div className='font-EBGaramond text-slate-700 ml-8 text-center mb-5  mt-5'>
    <NameWrapper>
        <label htmlFor="role">Role:</label>
          <div  className='ml-5 border-b border-gray-800'>
            <select
              id="role"
              value={role}
              onChange={handleRoleChange}
              required
              
            >
              <option value="">Select Role</option>
              {Roles.map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>
          </div>
          </NameWrapper>
        </div>







  {/* _____________________________________________________________________________________________________ */}











{/* ________________________________________________________________________________________________________ */}
<div className='font-EBGaramond text-slate-700  text-center mb-3  mt-5 '>
          <label htmlFor="file">Upload Resume   </label>
          <input type="file" id="file" onChange={handleFileChange} />
        </div>
        <button 
         className="m-5 bg-white border-2 text-lg border-orange-600 rounded-md py-0 px-12 text-orange-600 font-bold shadow-md hover:bg-orange-600 hover:text-white transition duration-300 ease-in-out"
        type="submit">Submit</button>
      </form>
    </div>
  );
};

export default CareerForm;
