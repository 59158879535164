import * as THREE from 'three'
import { useRef, useState, useMemo, useEffect } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Text, TrackballControls } from '@react-three/drei'

function Word({ children, ...props }) {
  const color = new THREE.Color()
  const fontProps = { font: '/Inter-Bold.woff', fontSize: 2.5, letterSpacing: -0.05, lineHeight: 1, 'material-toneMapped': false }
  const ref = useRef()
  
  useFrame(({ camera, clock }) => {
    // Make text face the camera
    ref.current.quaternion.copy(camera.quaternion)
    ref.current.lookAt(camera.position)
    
  })
  return <Text ref={ref}  onClick={() => console.log('clicked')} {...props} {...fontProps} children={children} />
}


function Cloud({  count = 4, radius = 20 }) {
  const ref1 = useRef()
  useFrame(({ camera, clock }) => {
    if (ref1.current) {
      ref1.current.rotation.x = clock.elapsedTime * 0.1;
      ref1.current.rotation.y = clock.elapsedTime * 0.1;
      ref1.current.rotation.z = clock.elapsedTime * 0.2;
    }
  })

  // Create a count x count random words with spherical distribution
    const words = useMemo(() => {
    let KPIs = ['Installation', 'Start-up', 'Commissioning', 'Retrofit', 'Production Support', 'Performance Improvement',  'Technical Audit',' Diagnosis', 'Consulting', 'Technical training', 'Electrical Installation',  'Waste Reduction', 'Electrical design', 'Automation', 'Breakdown Assistance' ];
    const temp = []
    const spherical = new THREE.Spherical()
    const phiSpan = Math.PI / (count + 1)
    const thetaSpan = (Math.PI * 2) / count
    for (let i = 1; i < count + 1; i++)
      for (let j = 0; j < count; j++) temp.push([new THREE.Vector3().setFromSpherical(spherical.set(radius, phiSpan * i, thetaSpan * j)), 
            KPIs[Math.floor(Math.random() * KPIs.length)]  
            ])
    return temp
  }, [count, radius])


  return (
 <group ref={ref1}>
 {words.map(([pos, word], index) => (<Word key={index} position={pos} children={word} />))}
</group>
  )
}


export default function Kpi() {
  return ( 
      <Cloud  count={7} radius={40} />
  )
}
