import styled from 'styled-components'

export default function Landing_Line()
{

    return(
         
<div className="loader land-positioned flex flex-wrap text-center absolute z-20 ">
 
       <div className="font-Montserrat text-white ">Elevate your manufacturing to new heights with</div>
       <div className="words inline-block ">
           <span className="word font-Montserrat py-0 relative text-orange-600">Digitilization.</span>
           <span className="word font-Montserrat py-0 relative text-orange-600">Real-time Analytics.</span>
           <span className="word font-Montserrat py-0 relative text-orange-600">Smart Factory Solutions.</span>
           <span className="word font-Montserrat py-0 relative text-orange-600">Digital Twins.</span>
           <span className="word font-Montserrat py-0 relative text-orange-600">Digitilization.</span>
       </div>
     
 <style >{`

.land-positioned {
  position: absolute;
  top:14%;
  justify-content: center; /* Center content vertically */
  text-align: center; /* Center content horizontally */
  }
      
 `}</style>
</div>
    );
}




