export default function Focus(){

    return (


<svg className="icon icon-tabler icon-tabler-focus-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <circle cx="12" cy="12" r=".5" fill="currentColor"></circle>
   <path d="M12 12m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
   <path d="M12 3l0 2"></path>
   <path d="M3 12l2 0"></path>
   <path d="M12 19l0 2"></path>
   <path d="M19 12l2 0"></path>
</svg>





    );
}


