export default function Energy(){

    return (


<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bolt  text-orange-700" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M13 3l0 7l6 0l-8 11l0 -7l-6 0l8 -11"></path>
</svg>





    );
}


