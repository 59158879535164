

import BottomPage from "../Bottom";
import './Cu.css';
import Image_Cu from "./Image_Cu";
import styled from "styled-components";
import { useEffect } from 'react';
import React from 'react';
import ScrollToTop1 from '../Utilities/ScrollToTop1';



const Overflow_Cu = styled.main`
// width: auto;
// height: 100%;

Overflow-y: hidden;
Overflow-x: hidden;
`;


export default function Career() {

// Automatically scroll to the top of the page - 

useEffect(() => {
  window.scrollTo(0, 0);
}, []);

 

  return (
<Overflow_Cu>
  

    <Image_Cu/>

  {/* <BottomPage></BottomPage> */}
  <ScrollToTop1/>
</Overflow_Cu>
    
  );
}
