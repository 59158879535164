import ThreeBox from "../Utilities/ThreeBox";
import styled from 'styled-components'








const ThreeBoxContainer = styled.div`
position: absolute;


z-index: 1;

/* Media Queries */
@media screen and (min-width: 1440px) and (max-width: 3500px) {
  transform: scale(0.8);
  right: 10%;
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  transform: scale(0.8);
  padding-top: 24px;
  right: 10%;
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
  transform: scale(0.8);
  padding-top: 24px;
  right: 0%;
}

@media screen and (max-width: 631px) {
  transform: scale(0.5);
  padding-top: 60px;
  right: -10%;
  
}
`;




export default function WaveLine(){



 

    return(



<div className="WL_background">


              <div className="WL_response"> 
                      <p className="font-Kanit px-28 ">
                      A tailored Al/ML solution for Manufacturing <span className="text-orange-600">Asset Life Extension.</span> 
                      </p>
                      <ThreeBoxContainer>
                      <ThreeBox></ThreeBox>
                      </ThreeBoxContainer>
                      
              </div>
              






           
 <style >{`

.WL_background{
  background-color: #303035;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

}

 .WL_response {
  
  display: flex;
  flex-direction: column;
  align-items: center;
 
  



     
     
   
     
   }
   
       @media screen and (min-width: 1440px) and (max-width: 3500px) {
        .WL_response {
          font-size: 36px;
          padding-top :35px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 70px;
        }
      }
      
      @media screen and (min-width: 1000px) and (max-width: 1440px) {
        .WL_response {
          font-size: 26px;
          padding-top :40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 120px;
        }
      }
      
      @media screen and (min-width: 631px) and (max-width: 1000px) {
        .WL_response {
          font-size: 26px;
          padding-top :30px;
          display: flex;
          flex-direction: column;
          transform: translateX(-5%);
          padding-bottom: 90px;
          
        }
      }
      
      
      
      @media screen and (max-width: 631px) {
        .WL_response {
          padding-top :30px;
          font-size: 20px;
          display: flex;
          flex-direction: column;
          padding-bottom: 70px;
          transform: translateX(-20%);
          
        }
      }
      
 
 
 
 
 
 
 
  `}</style>
 </div>
     );
 }
 
 