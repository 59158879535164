import React, { useLayoutEffect } from 'react'

import styled from 'styled-components'
import Canvas_Services from './Canvas_Services'
import { Gradient } from '../lib/Gradient.js'

import ServiceLine2 from './ServiceLine2.js'


export default function Services_Section2() {


  return (
    
<div>
              
                    <CanvasContainer_Sr>
                                      <Canvas_Services />
                    </CanvasContainer_Sr>
            

<ServiceLine2></ServiceLine2>

            
     
</div>
            

  
  )
}

const Main1_Sr = styled.main`
position: relative;
display: flex;
flex-direction: column;
padding-top :0%;

width: 100%;
height: auto;
 
`

const Main_Sr = styled.main`
position: relative;
display: flex;
flex-direction: row;
width: 100%;
height:auto;

@media only screen and (max-width: 1200px) {
  flex-direction: column;
  }
`



const CanvasContainer_Sr = styled.div`
position: relative;
flex: 1 1 0;
width: 100vw;
height: 50vh;
padding-top: 3em;
padding-right: 0em;

`


















const CloudLineWrapper_Sr = styled.main`
position: relative;
margin-top: 0%;
margin-bottom: 0%;

`;