export default function Pharma(){

    return (


<svg className="icon icon-tabler icon-tabler-crystal-ball text-orange-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="#a1410a" strokeLinecap="round" strokeLinejoin="round">
<path d="M8.42896 8.57104L15.5 15.6421M8.42896 8.57104L4.89342 12.1066C2.9408 14.0592 2.9408 17.225 4.89342 19.1776C6.84604 21.1303 10.0119 21.1303 11.9645 19.1776L15.5 15.6421M8.42896 8.57104L11.9645 5.03551C13.9171 3.08289 17.0829 3.08289 19.0356 5.03551C20.9882 6.98813 20.9882 10.154 19.0356 12.1066L15.5 15.6421" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>




    );
}

