import React, { useLayoutEffect } from 'react'

import styled from 'styled-components'
import Canvas_FMCG from './Canvas_FMCG.js'
import Colab from '../svgs/Colab.js';
import FMCG_Report from '../svgs/FMCG_Report.js'
import FMCG_Remote from '../svgs/FMCG_Remote.js';
import FMCG_Data from '../svgs/FMCG_Data.js'
import FMCG_Quality from '../svgs/FMCG_Quality.js'
import FMCG_MTTR from '../svgs/FMCG_MTTR.js';



export default function Android_fmcg() {


  return (
    
    
       <Main1>

          <Main >
              
              
                  <CanvasContainer>
                                      <Canvas_FMCG/>
                    </CanvasContainer>

                    <ContentContainer>
                      <Content>
                      
                                      <h1 className='font-Kanit text-slate-900 pt-3'> Revolutionize the businesses by achiving greater efficiency and agility, improving decision-making processes and reducing operational costs.</h1>
                                      <SvgGrpWrapper>
                                      <h1><SvgGrp1Wrapper><Colab></Colab><div className='text-black pl-5 pt-5'>Predictive Capabilities</div></SvgGrp1Wrapper>
                                           <SvgGrp1Wrapper><FMCG_Report></FMCG_Report><div className='text-black pl-5 pt-5'>Data Driven Insights</div></SvgGrp1Wrapper> 
                                           <SvgGrp1Wrapper><FMCG_Remote></FMCG_Remote><div className='text-black pl-5 pt-5'>Better Team Collaboration</div></SvgGrp1Wrapper></h1>
                                      <h1>
                                        <SvgGrp2Wrapper><FMCG_Data></FMCG_Data><div className='text-black pl-5 pt-5'>Increased Productivity</div></SvgGrp2Wrapper>
                                         <SvgGrp2Wrapper><FMCG_Quality></FMCG_Quality><div className='text-black pl-5 pt-5'>Quality Adherence</div></SvgGrp2Wrapper>
                                        <SvgGrp2Wrapper><FMCG_MTTR></FMCG_MTTR><div className='text-black pl-5 pt-5'>Reduction in MTTR</div></SvgGrp2Wrapper>
                                      </h1>
                                      </SvgGrpWrapper>
                                      <h1 className='font-EBGaramond text-slate-700 py-4'>Embracing digital transformation is key to staying competitive and thriving in today's fast-paced, technology-driven market. </h1>

                      </Content>
                                       

                    </ContentContainer>
                    
                   
            </Main>
          
           
            </Main1>

                         

            

  
  )
}

const BottomWrapper = styled.div`
position:relative;

transform: scale(1.12);
margin-top:20%;






`;

const Main1 = styled.main`
position: relative;
display: flex;
flex-direction: column;
padding-top :0%;
margin-bottom: 5%;
width: 100%;
height: auto;
 
`

const Main = styled.main`
position: relative;
display: flex;
flex-direction: row;
width: 100%;
height:auto;

@media only screen and (max-width: 1200px) {
  flex-direction: column;
  
  }



`


const CanvasContainer = styled.div`
position: relative;
flex: 1 1 0;
min-width: 0;
min-height: 200px;
display: flex;
justify-content: center;
padding-top: 3em;
padding-right: 0em;
@media only screen and (max-width: 1200px) {
  padding-right: 0;
  order: -1;


  /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    min-height: 700px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    min-height: 700px;
  }

  @media screen and (min-width: 800px) and (max-width: 1200px) {
    order: -1;
    min-height: 600px;
  }
  @media screen and (min-width: 500px) and (max-width: 800px) {
    order: -1;
    min-height: 350px;
  }
    @media screen and (min-width: 370px) and (max-width: 500px) {
    order: -1;
    min-height: 350px;
    
  }

  

}
`

const ContentContainer = styled.div`
position: relative;
flex: 1 1 0;
  min-width: 0;
  min-height: 500;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  @media only screen and (max-width: 1200px) {
    margin-top: 0;
  }
`
const Content = styled.div`
  flex: 1;
  padding-left: 4em;

  h2 {
    // color: #f7057e;
    font-size: 2rem;
    margin-top: 0.5em;
    padding: 0;
    line-height: 0;
    margin-bottom: 0em;
    white-space: nowrap;
  }

  h3 {
    float: right;
    text-align: right;
    width: 100px;
    font-size: 0.8rem;
  }

  h1 {
    font-size: 1.6rem;
  
    
  }

  p {
    font-size: 0.8rem;
    width: 200px;
  }

  @media only screen and (max-width: 1200px) {
    width: 100%;
    padding-right: 2em;
    padding-left: 2em;
    h1 {
      font-size: 1.6rem;
      line-height: 2rem;
    }

    h2 {
      font-size: 2.3rem;
      line-height: 2.3rem;
      margin-bottom: 0.8rem;
    }
  }

  @media only screen and (max-width: 1000px) {
    h1 {
      font-size: 1.6rem;
      line-height: 2rem;
    }

    h2 {
      font-size: 1.6rem;
      line-height: 1.6rem;
    }
  }

  @media only screen and (max-width: 600px) {
    h1 {
      font-size: 1.3rem;
      line-height: 1.8rem;
      padding-bottom: 1.5em;
    }

    h2 {
      font-size: 1.3rem;
      line-height: 1.3rem;
    }
  }
`



const SvgGrpWrapper = styled.main`
position: relative;
display: flex;
flex-direction: row;
padding-top :0.5rem;
width: 100%;
height: auto;

@media screen and (max-width: 600px)
 {
  flex-direction: column;
  }
}

`;
const SvgGrp1Wrapper = styled.main`
position: relative;
display: flex;
flex-direction: row;
padding-top :10%;
padding-bottom :10%;
padding-right :3em;


`;
const SvgGrp2Wrapper = styled.main`
position: relative;
display: flex;
flex-direction: row;
padding-top :10%;
padding-bottom :10%;
padding-left :3em;

@media screen and (max-width: 600px)
 {
  padding-left :0em;;
  }
}
`;