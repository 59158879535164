import styled from 'styled-components'
import Landing_Line from './Landing_Line';

import Video from './Video';
import WaveLine from './WaveLine';


const Overflow = styled.main`
  width: 100%;
  height: 100%;




`;

const VideoContainer = styled.main`
position: relative;
  top: -25%;

  /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    transform: scale(1.2);
    width: 110%;
    right:0%;
  }
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    transform: scale(1.1);
    right:5%;
  }

  @media screen and (min-width: 700px) and (max-width: 1000px) {
    transform: scale(1.1);
    right:16%;
  }
  
  @media screen and (min-width: 400px) and (max-width: 700px) {
    transform: scale(1.1);
    right:16%;
  }

  @media screen and (max-width: 400px) {
    transform: scale(1.1);
    right:18%;
`;


const WaveContainer = styled.main`
position: relative;


  /* Media Queries */

  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    transform: scale(1.1);
    top: -22%;
  }

  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    transform: scale(1.1);
    top: -25%;
  }

  @media screen and (min-width: 630px) and (max-width: 1000px) {
    transform: scale(1.1);
    top: -25%;
  }
  @media screen and (min-width: 400px) and (max-width: 630px) {
    transform: scale(1);
    top: -25%;
  }

  @media screen and (max-width: 400px) {
    transform: scale(1.1);
    top: -34%;



`;



export default function Product_Section1() {
  return (
    <Overflow>
      
      <Landing_Line></Landing_Line>
  
      <VideoContainer>
      <Video></Video>
      </VideoContainer>
      <WaveContainer>
     <WaveLine></WaveLine>
      </WaveContainer>
 
        

    

       
        
       
        

        
        

        
      
    </Overflow>
  );
}