export default function RFID(){

    return (


<svg  className="icon icon-tabler icon-tabler-router" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M3 13m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"></path>
   <path d="M17 17l0 .01"></path>
   <path d="M13 17l0 .01"></path>
   <path d="M15 13l0 -2"></path>
   <path d="M11.75 8.75a4 4 0 0 1 6.5 0"></path>
   <path d="M8.5 6.5a8 8 0 0 1 13 0"></path>
</svg>





    );
}


