export default function Down(){

    return (


<svg className="icon icon-tabler icon-tabler-trending-down" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M3 7l6 6l4 -4l8 8"></path>
   <path d="M21 10l0 7l-7 0"></path>
</svg>





    );
}


