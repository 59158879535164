import styled from 'styled-components'
import Call from './svgs/Call';
import Mail from './svgs/Mail';
import Address from './svgs/Address';
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react"
import { Button } from 'react-scroll';


const Overflow_Bt = styled.main`
// width: auto;
// height: 100%;

Overflow-y: hidden;
Overflow-x: hidden;

`;
const TopicWrapper = styled.main`
position: relative;
bottom:0;
display: flex;
justify-content: center;
align-items: center; 
padding-top:5%;
padding-bottom:5%;
flex-direction: column;


background-color: #303042;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;


const DemoWrapper = styled.main`
position: relative;
text-align: center; 


/* Media Queries */
@media screen and (min-width: 1440px) and (max-width: 3500px) {
font-size: 65px;
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
font-size: 65px;
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
font-size: 65px;
}

@media screen and (max-width: 631px) {
font-size: 35px;

}
`;

const ColorWrapper = styled.main`
background-color: #303035;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
display: flex;
position:relative;

/* Media Queries */

@media screen and (min-width: 1440px) and (max-width: 3500px) {
  flex-direction: row;
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  flex-direction: row;
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
  flex-direction: row;
}

@media screen and (max-width: 631px) {
  flex-direction: column;

}
`;

const LeftWrapper = styled.main`
position: relative;
top: 0;
left: 15px;
`
const SpaceBetween = styled.main`
top: 0;
right: 15px;



/* Media Queries */

@media screen and (min-width: 1440px) and (max-width: 3500px) {
  margin-left : 30%;
  flex-direction: row;
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  margin-left : 20%;
  flex-direction: row;
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
  margin-left : 10%;
  flex-direction: row;
}

@media screen and (max-width: 631px) {
  margin-left : 2%;
  flex-direction: column;
}
`

const RightWrapper = styled.main`
top: 0;
margin-left:5%



/* Media Queries */


`

const CopyrightWrapper = styled.main`

display: flex;
flex-direction: column;
align-items: center;
text-align: center;

background-color: #3f3f45;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`




const BottomPage = ({ handleClick_App }) =>
{
  const location = useLocation();
  const navigate = useNavigate();
  
  
  const handleButtonClickPage1 = (id) => {
    handleClick_App(id)
    if (location.pathname !== '/Page1') {
      navigate('/Page1');
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };






  
const handleButtonClickPage2 = (id) => {
  handleClick_App(id)
  if (location.pathname !== '/Page2') {
    navigate('/Page2');
  } else {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};



const handleButtonClickPage3 = (id) => {
  handleClick_App(id)
  if (location.pathname !== '/Page3') {
    navigate('/Page3');
  } else {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};


const handleButtonClickPage4 = (id) => {
  handleClick_App(id)
  if (location.pathname !== '/Page4') {
    navigate('/Page4');
  } else {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};


const handleButtonClickPage5 = (id) => {
  handleClick_App(id)
  if (location.pathname !== '/Page5') {
    navigate('/Page5');
  } else {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};



const handleButtonClickPage6 = (id) => {
  handleClick_App(id)
  if (location.pathname !== '/Page6') {
    navigate('/Page6');
  } else {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};


    return(
      <div>
        <Overflow_Bt>
<TopicWrapper>

              <DemoWrapper>
                            <div className='font-Kanit mb-0 '>
                              READY FOR A DIGITAL TRANSFORMATION?
                            </div>
              </DemoWrapper>

              <div>
                            <button className='px-3 py-3 mt-4 text-black bg-white font-Montserrat font-bold text-2xl'
                            onClick={() =>handleButtonClickPage6(6)}
                            >
                              GET STARTED
                            </button>
              </div>

              <div className='pt-5 font-Montserrat ' style={{ textAlign: 'center' }}>
  Need an Electrical/Automation Intervention.
  <button
   onClick={() =>handleButtonClickPage6(6)}
   ><span className='text-orange-600 font-bold underline ml-2'>Book FSE</span></button>
</div>

              
</TopicWrapper>

<ColorWrapper>

              <LeftWrapper>
                            <div className='ml-3 font-Kanit  text-white pt-7'>
                            <button className='underline' onClick={() =>handleButtonClickPage6(6)}>
                              Get In Touch With Us
                              </button>
                            </div>

                            <div className='ml-3 font-EBGaramond text-gray-400 pt-2'>
                              Contact Us
                            </div>

                            <div className='flex items-center pt-2'>
                                <span><Call /></span>
                                <a href='tel:+918923323099' className='text-gray-400 font-EBGaramond pl-3'>
                                  +91-8923323099
                                </a>
                              </div>

                            <div className='flex items-center pt-2'>
                                <span><Mail /></span>
                                <a href='mailto:info@intellimeta.ai' className='text-gray-400 font-EBGaramond pl-3'>
                                  info@intellimeta.ai
                                </a>
                              </div>


                            <div className='flex items-center pt-2'>
                              <span> <Address/> </span>
                              <span className='text-gray-200 font-EBGaramond pl-3'> Gurugram Office </span>
                            </div>
                            <div className='ml-3 mt-2 font-EBGaramond text-gray-400'>
                              <div>HD-235, WeWork Forum Building,</div>
                              <div>DLF CyberCity, DLF Phase III  </div>
                              <div>Haryana, India (122002) </div>
                            </div>

              </LeftWrapper>
<SpaceBetween></SpaceBetween>
              <RightWrapper>
                                                          <div className='ml-3 font-Kanit text-white pt-7'>
                                                  <button className='underline' onClick={() =>handleButtonClickPage1(1)}>
                                                    Digital Solutions
                                                  </button>
                                                </div>

                            <div className='ml-3 font-EBGaramond text-gray-400 pt-2'>
                              Breakdown Prediction
                            </div>
                            <div className='ml-3 font-EBGaramond text-gray-400 pt-1'>
                              Plant 3D Monitoring
                            </div>
                            <div className='ml-3 font-EBGaramond text-gray-400 pt-1'>
                              Energy Optimization
                            </div>
                            <div className='ml-3 font-EBGaramond text-gray-400 pt-1'>
                              Inventory Management
                            </div>
                            <div className='ml-3 font-EBGaramond text-gray-400 pt-1'>
                              Performance Dashboard
                            </div>
                            
              </RightWrapper>
              <RightWrapper>
              <div className='ml-3 font-Kanit  text-white pt-7'>
              <button className='underline' onClick={() =>handleButtonClickPage2(2)}>
                              Services</button>
                            </div>

                            <div className='ml-3 font-EBGaramond text-gray-400 pt-2'>
                              Installation
                            </div>
                            <div className='ml-3 font-EBGaramond text-gray-400 pt-1'>
                              Comissioning & Startup
                            </div>
                            <div className='ml-3 font-EBGaramond text-gray-400 pt-1'>
                              Troubleshooting
                            </div>
                            
                            
              </RightWrapper>
              <RightWrapper>
              <div className='ml-3 font-Kanit  text-white pt-7'>
              <button className='underline' onClick={() =>handleButtonClickPage3(3)}>
                Industries</button>
                            </div>

                            <div className='ml-3 font-EBGaramond text-gray-400 pt-2'>
                              Oil & Gas
                            </div>
                            <div className='ml-3 font-EBGaramond text-gray-400 pt-1'>
                              FMCG
                            </div>
              </RightWrapper>
              <RightWrapper>
              <div className='ml-3 font-Kanit  text-white pt-7'>
              <button className='underline' onClick={() =>handleButtonClickPage4(4)}>
                About Us</button>
                            </div>
                            <div className='ml-3 font-Kanit  text-white pt-7'>
                            <button className='underline' onClick={() =>handleButtonClickPage5(5)}>
                              Career</button>
                            </div>
                            <div className='ml-3 font-Kanit  text-white pt-7'>
                            <button className='underline' onClick={() =>handleButtonClickPage6(6)}>
                              Contact Us</button>
                            </div>
                            

                            
              </RightWrapper>
              
           

</ColorWrapper>

<CopyrightWrapper>
  <div className=' mt-4 mb-4 flex justify-center text-xs text-gray-400'> All rights reserved by Intelimeta Pvt. Ltd. © 2023 <span className='text-gray-600 pl-4'> v1 </span> </div>
</CopyrightWrapper>
</Overflow_Bt>
</div>


    );
}


export default BottomPage;