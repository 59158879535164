import React from 'react';
import styled from 'styled-components';
import './AboutUs.css';
import Image_Content_As from './Image_Content_As';

const ImageContainer_As = styled.div`
  position: relative;
  z-index: 1;
`;

const Image_AboutUs = styled.img`
/* Set your desired maximum width */
  z-index:1;
  height: auto;

  @media screen and (min-width: 1777px) and (max-width: 5500px) {
    width: 100%;
  }
  @media screen and (max-width: 1777px) {
    max-width: 2000px;
  }
`;


const ImageMask_As = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(48, 48, 53, 0.8);/* Adjust the alpha value for desired transparency */
`;

export default function Image_As() {
  return (
    <div className='container_As'>
      <ImageContainer_As>
        <Image_AboutUs src="./Metaverse.png" alt="About US Page" />
        <ImageMask_As />
      </ImageContainer_As>
      <Image_Content_As></Image_Content_As>
    </div>
  );
}
