import './Cr.css';
import CareerForm from './CareerForm';



import styled, { keyframes } from 'styled-components';
import { Transition } from 'react-transition-group';

// Define the keyframes for the fade-in animation
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FadeInWrapper_Cr = styled.div`
  animation: ${fadeIn} 1s ease-in-out;
`;





export default function Image_Content_Cr()
{



    return(




<div className="content_As">
              





<Transition in={true} appear={true} timeout={1000}>
      {(state) => (
        <FadeInWrapper_Cr style={{ transition: 'opacity 1s ease-in-out', opacity: state === 'entered' ? 1 : 0 }}>
         
            <TopicWrapper_Cr> Student Program </TopicWrapper_Cr>
            <Description_Cr>
            <div className="font-Montserrat">
              Ready to transform the manufacturing world with us? Lets create a difference together!!
            </div>
           
            <div className="font-Montserrat"> We’re currentely hiring undergraduate and graduate students for internship and full-time opportunities in a variety of roles, including UI/UX Designer , React.js Developer, React Native Developer, CAD Designer, NLP AI Platform Engineer, AI/ML Engineer, Cloud Architect, PLC Programer, Electrical & Automation Engineer, and more.  </div>
            <CareerForm/>
            
            </Description_Cr>
           
        </FadeInWrapper_Cr>
        
      )}
    </Transition>








      </div>





    );

}


const TopicWrapper_Cr = styled.main`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Kanit;
    margin-top:2;
    padding-bottom:2%;


    /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    font-size: 35px;
    padding-top:10%;
    padding-bottom:2%;
    
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    font-size: 35px;
    padding-top:10%;
    padding-bottom:2%;
  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    font-size: 35px;
    padding-top:10%;
    padding-bottom:2%;
    
  }
  
  @media screen and (max-width: 631px) {
    font-size: 30px;
    padding-top:10%;
    padding-bottom:2%;

  `;


  const Description_Cr = styled.main`
    position:relative;  
    font-family: Montserrat;



    /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    font-size: 18px;
    margin-top: 1%;
    
  }
  
  @media screen and (min-width: 1100px) and (max-width: 1440px) {
    font-size: 16px;
    margin-top: 1%;
  }
  
  @media screen and (min-width: 631px) and (max-width: 1100px) {
    font-size: 14px;
    margin-top: 4%;
  }

  @media screen and (min-width: 370px) and (max-width: 631px) {
    font-size: 16px;
    margin-top: 2%;
  }
  
  @media screen and (max-width: 631px) {
    font-size: 10px;
    margin-top: 2%;

  `;

