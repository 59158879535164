import ReactPlayer from 'react-player';

const Video = () => {
  const isMobileScreen = window.innerWidth < 2500;

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        style={{
          width: isMobileScreen ? '2500px' : '110%',
          maxWidth: isMobileScreen ? '2500px' : '110%',
          margin: '0 auto',
          
        }}
      >
        <ReactPlayer
          url='./vid.mp4'
          playing
          muted
          loop
          playsinline
          autoPlay 
          width={isMobileScreen ? '2500px' : '110%'}
          height='100vh'
          style={{ display: 'block', margin: '0 auto' }}
        />
      </div>
    </div>
  );
};

export default Video;
