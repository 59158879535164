import styled from 'styled-components'
import ServiceLine from './ServiceLine1.js'


export default function Services_Section1() {

  return (
       <MainServices>

<ServiceLine></ServiceLine>
    
     </MainServices>
      

  
  )
}

const MainServices = styled.main`
position: relative;
display: flex;
flex-direction: column;
padding-top :3%;
width: 100%;
height: auto;
`


