import React, { useLayoutEffect } from 'react'

import styled from 'styled-components'




import Analytics from '../svgs/Analytics.js'

import Reports from '../svgs/Reports.js'
import Accessible from '../svgs/Accessible.js'
import OnTime from '../svgs/OnTime.js'
import Inventory from '../svgs/Inventory.js'







export default function Module5Card() {
  

  return (
    

<div>
    <ModuleHeading className='text-3xl font-bold font-Montserrat tracking-tight'>KPI Dashboards - Automated tracking!! </ModuleHeading>

    
        <ModuleWrapper>

                       

{/* Module 1 - Intelliscope -  Remote Asset Monitoring -----------------------------------------------------------------------*/}

                                <div>
                                <div className='ModuleCard'> 
                        <div className='relative py-4 text-center '> Performance Dashboard </div>
                    <div>
                    <div className="font-Montserrat text-xs text-black p-2 flex items-center">
                                                      <span className="ml-2"><Analytics/></span>
                                                      <span className="ml-2">Automated OEE Calculation</span>
                                                    </div>
                                                    <div className="font-Montserrat text-xs text-black p-2 flex items-center">
                                                      <span className="ml-2"><Inventory/></span>
                                                      <span className="ml-2">Inventory Turnover visibility</span>
                                                    </div>
                                                    <div className="font-Montserrat text-xs text-black p-2 flex items-center">
                                                      <span className="ml-2"><OnTime/></span>
                                                      <span className="ml-2">On-Time Delivery Tacking</span>
                                                    </div>
                                                    <div className="font-Montserrat text-xs text-black p-2 flex items-center">
                                                      <span className="ml-2 text-yellow-600" ><Accessible/></span>
                                                      <span className="ml-2">Quality & Safety Interlocks Visibility </span>
                                                    </div>
                                                    <div className="font-Montserrat text-xs text-black p-2 flex items-center">
                                                      <span className="ml-2"><Reports/></span>
                                                      <span className="ml-2">Automated Yield Reports</span>
                                                    </div> 
                                                              </div>
                                    </div>
                                </div>    

{/*SLASH Module 1 - Intelliscope -  Remote Asset Monitoring -----------------------------------------------------------------------*/}


                                <div>                                
                                      {/* <div className="vertical-line"></div> */}

                                      <div>
                                        <ModuleContent className=' text-slate-700'>
                                        <VerticalL4 className='text-white'>.</VerticalL4>
                                        Track and analyze your key performance indicators (KPIs) with ease using our automated calculation solution. Our performance dashboard provides real-time insight into your operations. Gain insight into inventory turnover and maintain visibility into the monitoring of on-time deliveries. With visibility into quality and safety interlocks, you can proactively address potential problems. Enjoy the convenience of automated yield reports, which enable you to make decisions based on data for enhanced productivity
 </ModuleContent></div>
                                        
                                        
                                      
                                  </div>      

          </ModuleWrapper>
    </div>
  
  )
}

const ModuleHeading = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
  background: linear-gradient(174deg, #0c96f1, #1623db, #d1082d);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            /* Media Queries */
            @media screen and (min-width: 1440px) and (max-width: 3500px) {
              padding-top: 1%;
              padding-bottom: 1%;
            }
            
            @media screen and (min-width: 1000px) and (max-width: 1440px) {
              padding-top: 1%;
              padding-bottom: 2%;
            }
            
            @media screen and (min-width: 631px) and (max-width: 1000px) {
              padding-top: 5%;
              padding-bottom: 5%;
            }
            
            @media screen and (max-width: 631px) {
              padding-top: 5%;
              padding-bottom: 5%;
            }
  
`;
const ModuleWrapper = styled.main`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  

  /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    flex-direction: row;
    text-align: left;
    margin-left: 5%;
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    flex-direction: row;
    margin-left: 5%;
  
  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    flex-direction: column;
   
  }
  
  @media screen and (max-width: 631px) {
    flex-direction: column;
    
  }
  
`;




const ModuleContent = styled.main`
  position: relative;
  display: flex;
  align-items: center;
  width:90%;

 

  /* Media Queries */

  @media screen and (min-width: 2001px) {
    font-size: 23px;

  @media screen and (min-width: 1440px) and (max-width: 2000px) {
    font-size: 23px;
    padding-right: 15%;
  }


  @media screen and (min-width: 1440px) and (max-width: 2000px) {
    font-size: 23px;
    padding-right: 15%;
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    font-size: 20px;
  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    font-size: 18px;
    margin-left:5%; 
  }
  
  @media screen and (max-width: 631px) {
    padding-top:15%;
    margin:10%;
    font-size: 8px;
  
`;

const VerticalL4 = styled.main`
 {
width: 5px;
background-color: #fb8c00; 
margin-left:5%;
margin-right:5%;

@media screen and (min-width: 1440px) and (max-width: 3500px) {
  height: 255px;
}
@media screen and (min-width: 1000px) and (max-width: 1440px) {
  height: 255px;
}
@media screen and (min-width: 631px) and (max-width: 1000px) {
  height: 255px;
}
@media screen and (min-width: 450px) and (max-width: 631px) {
  height: 355px;
}
@media screen and (min-width: 400px) and (max-width: 450px) {
  height: 350px;
}
@media screen and (max-width: 400px) {
  height:350px;

`








