import CloudLine from "./CloudLine";
import Industries_Section1 from "./Industries_Section1";
import styled from "styled-components";
import BottomPage from "../Bottom";
import { useEffect } from 'react';
import ScrollToTop1 from '../Utilities/ScrollToTop1';

  

const Overflow_Ir = styled.main`
// position:absolute;
width: 100%;
height: 100%;
Overflow-x: hidden;
// Overflow-y: hidden;
  `;

export default function Industries()
{

// Automatically scroll to the top of the page - 

useEffect(() => {
  window.scrollTo(0, 0);
}, []);


  return(
<Overflow_Ir>
<Industries_Section1></Industries_Section1>
{/* <div className="relative">
<BottomPage></BottomPage>
</div> */}
<ScrollToTop1/>

</Overflow_Ir>

  );
}