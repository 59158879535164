import styled from 'styled-components'
import Product_Section1 from "./Product_Section1";
import Product_Section2 from "./Product_Section2";
import Product_Section3 from "./Product_Section3";
import BottomPage from '../Bottom';
import { useEffect } from 'react';
import ScrollToTop1 from '../Utilities/ScrollToTop1';




const Overflow_Pr = styled.main`
// width: auto;
// height: 100%;

Overflow-y: hidden;
Overflow-x: hidden;

`;


export default function Product()
{

// Automatically scroll to the top of the page - 

useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  return (
    <Overflow_Pr>

<Product_Section1></Product_Section1>
        
<Product_Section2></Product_Section2>
        
        <Product_Section3></Product_Section3>
     
        
      {/* <BottomPage></BottomPage> */}
      <ScrollToTop1/>
 
      
    </Overflow_Pr>
  );
}