export default function Locate(){

    return (


<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-map-search" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M11 18l-2 -1l-6 3v-13l6 -3l6 3l6 -3v8"></path>
   <path d="M9 4v13"></path>
   <path d="M15 7v5"></path>
   <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
   <path d="M20.2 20.2l1.8 1.8"></path>
</svg>





    );
}


