export default function Cement(){

    return (


<svg className="icon icon-tabler icon-tabler-crystal-ball text-orange-800" width="24" height="24" viewBox="0 0 50 50" strokeWidth="2" stroke="currentColor" fill=" none" strokeLinecap="round" strokeLinejoin="round">
<path d="M13 5C11.35503 5 10 6.3550302 10 8L10 9.1425781C8.279832 9.5899132 7 11.141562 7 13L7 18L13 18L13 20L7 20L7 26.349609C4.673 27.175609 3 29.393 3 32C3 34.607 4.673 36.824391 7 37.650391L7 43L1 43L1 45L47 45L49 45L49 44L49 43L46 43L46 24L44.935547 24L43.935547 9L40 9L39.132812 22L42 22L42 24L23 24L23 8C23 6.3550302 21.64497 5 20 5L13 5 z M 13 7L20 7C20.56503 7 21 7.4349698 21 8L21 24L17 24L17 43L15 43L15 13C15 11.141562 13.720168 9.5899132 12 9.1425781L12 8C12 7.4349698 12.43497 7 13 7 z M 9 28C11.206 28 13 29.794 13 32C13 34.206 11.206 36 9 36C6.794 36 5 34.206 5 32C5 29.794 6.794 28 9 28 z M 20 28L24 28L24 30L20 30L20 28 z M 26 28L30 28L30 30L26 30L26 28 z M 33 28L37 28L37 30L33 30L33 28 z M 39 28L43 28L43 30L39 30L39 28 z M 20 33L24 33L24 35L20 35L20 33 z M 26 33L30 33L30 35L26 35L26 33 z M 33 33L37 33L37 35L33 35L33 33 z M 39 33L43 33L43 35L39 35L39 33 z"/>
	</svg>




    );
}

