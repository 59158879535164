export default function Compliance(){

    return (


<svg className="icon icon-tabler icon-tabler-certificate" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M15 15m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
   <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5"></path>
   <path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73"></path>
   <path d="M6 9l12 0"></path>
   <path d="M6 12l3 0"></path>
   <path d="M6 15l2 0"></path>
</svg>





    );
}


