import React from 'react';
import Android_Ds from './Android_Ds';
import IOS_Ds from './IOS_Ds';



const Product_Section2 = () => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);


  if (isIOS) {
    // Render iOS-specific content
    return (
      <IOS_Ds></IOS_Ds>
    ) ;
  } else {
    // Render content for other devices or browsers
    return (
   <Android_Ds></Android_Ds>
    );
    
  }
};

export default Product_Section2;
