import { useRef } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { AsciiRenderer,TrackballControls, OrbitControls } from '@react-three/drei'
import Kpi from './Kpi'
import { useControls } from "leva"

export default function Canvas_Services() {


  // const {spot} = useControls({spot:{value: {x:10, y:10, z:10},step: 0.5}})



  return (
    <Canvas dpr={[1, 2]} camera={{ position: [0, 0, 66], fov: 90 }}>
      {/* <color attach="background" args={['black']} /> */}
      <spotLight position={[10, 10, 10]} angle={0.08} penumbra={1} />
      <pointLight position={[-10,-10,-10]} />
      <fog attach="fog" args={['#202025', 0, 80]} />
      <Kpi></Kpi>
      {/* <TrackballControls /> */}
      {/* <AsciiRenderer fgColor="white" bgColor="transparent"  characters =" 10111"  /> */}
    </Canvas>
  )
}


