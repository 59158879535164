export default function FMCG_Regulator(){

    return (



<svg fill="#474747" height="80px" width="80px" version="1.1" id="Capa_1" viewBox="0 0 299.102 299.102" >
<defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#cc2b5e" />
            <stop offset="100%" stopColor="#753a88" />
          </linearGradient>
        </defs>

<g>
	<path fill="url(#gradient)" d="M135.465,193.555c-24.264,0-44.004-19.74-44.004-44.004s19.74-44.004,44.004-44.004c8.284,0,15-6.716,15-15   s-6.716-15-15-15c-40.806,0-74.004,33.198-74.004,74.004s33.198,74.004,74.004,74.004c8.284,0,15-6.716,15-15   S143.75,193.555,135.465,193.555z"/>
	<path fill="url(#gradient)" d="M237.64,149.551c0-8.284-6.716-15-15-15h-41.369c6.147-16.9,22.37-29.004,41.369-29.004c8.284,0,15-6.716,15-15   s-6.716-15-15-15c-40.807,0-74.005,33.198-74.005,74.004s33.198,74.004,74.005,74.004c8.284,0,15-6.716,15-15s-6.716-15-15-15   c-18.999,0-35.222-12.104-41.369-29.004h41.369C230.924,164.551,237.64,157.835,237.64,149.551z"/>
	<path fill="url(#gradient)" d="M149.55,0C67.088,0,0,67.088,0,149.551s67.088,149.551,149.55,149.551c82.463,0,149.551-67.088,149.551-149.551   S232.013,0,149.55,0z M149.55,269.102C83.63,269.102,30,215.472,30,149.551S83.63,30,149.55,30   c65.921,0,119.551,53.63,119.551,119.551S215.471,269.102,149.55,269.102z"/>
</g>
</svg>



    );
}



// <svg fill="#474747" height="80px" width="80px" viewBox="0 0 32 32" id="icon">
// <defs>
//           <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
//             <stop offset="0%" stopColor="#cc2b5e" />
//             <stop offset="100%" stopColor="#753a88" />
//           </linearGradient>
//         </defs>
//   <title>ISO--filled</title>
//   <rect x="21" y="13" width="3" height="6"/>
//   <path  d="M28,6H4A2,2,0,0,0,2,8V24a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V8A2,2,0,0,0,28,6ZM8,21H6V11H8Zm9-8H12v2h3a2,2,0,0,1,2,2v2a2,2,0,0,1-2,2H10V19h5V17H12a2,2,0,0,1-2-2V13a2,2,0,0,1,2-2h5Zm9,6a2,2,0,0,1-2,2H21a2,2,0,0,1-2-2V13a2,2,0,0,1,2-2h3a2,2,0,0,1,2,2Z"/>
//   <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" className="cls-1" width="32" height="32"/></svg>

