export default function Data(){

    return (



<svg  fill="#474747" height="80px" width="80px" version="1.1" viewBox="0 0 512 512" enableBackground="new 0 0 512 512">
<defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#cc2b5e" />
            <stop offset="100%" stopColor="#753a88" />
          </linearGradient>
        </defs>
  <g>
    <g>
      <path fill="url(#gradient)" d="m480.6,460.1h-0.6v-315.1c0-11.3-8.9-20.5-20.4-20.4-11.3,0-20.4,9.1-20.4,20.4v315.1h-103.2v-219.9c0-11.3-9.1-20.5-20.4-20.4-11.3,0-20.4,9.1-20.4,20.4v219.9h-103.2v-159.9c0-11.3-9-20.5-20.4-20.4-11.3,0-20.4,9.1-20.4,20.4v159.8h-99.2v-420.2c0-11.3-8.9-20.4-20.4-20.4-11.4-7.10543e-14-20.6,9.2-20.6,20.5v440.7c0.3,15.8 14.3,21.2 20.4,20.4h449.1c11.3,0 20.4-9.1 20.4-20.4 0.1-11.3-8.9-20.4-20.3-20.5z"/>
      <path fill="url(#gradient)" d="m122,199.1c1,0.1 2.1,0.1 3.2,0 3.3-0.3 80-8.2 268.9-106.9l-4.5,18.4c-2.7,11 4.2,21.7 15,24.7 11.9,3.3 22.3-5.2 24.7-15l16.5-67.8c3.6-15.9-9.9-24-15.1-24.7l-68.7-16.2c-10.9-2.6-22,4.2-24.6,15.2-2.6,11 4.2,22 15.2,24.6l21.8,5.2c-178.3,93-251.6,101.8-253,102-11.2,1.1-19.4,11-18.4,22.1 0.9,10 9.1,17.8 19,18.4z"/>
    </g>
  </g>
</svg>




    );
}


