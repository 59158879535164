
import styled from 'styled-components'
import FB from '../svgs/F&B.js';
import OilDrill from '../svgs/OilDrill.js';
import Auto from '../svgs/Auto.js';
import Cement from '../svgs/Cement.js';
import Pharma from '../svgs/Pharma.js';

const TopicWrapper = styled.main`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom:3%;
    
    
    background-color: #303035;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    `;

    const SLWrapper = styled.main`
    position: relative;
    display:flex;
    text-align: center;

    /* Media Queries */

@media screen and (min-width: 1440px) and (max-width: 3500px) {
  font-size: 65px;
  margin-top:5%;
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  font-size: 39px;
  padding-top:3%;
  margin-top:2%;
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
  font-size: 39px;
  padding-top:4%;
  margin-top:7%;
  margin-bottom:3%

}

@media screen and (max-width: 631px) {
  font-size: 25px;
  padding-top:5%;
  margin-top:18%;
  margin-bottom:5%
}
    
    `;


  const DescriptionWrapper = styled.main`
  position: relative;
  display:flex;
  text-align: center;
  padding-top: 60px;
  font-size: 20px;
  margin-left: 5%;
  margin-right: 5%;

  @media screen and (max-width: 631px) {
    font-size: 14px;

  }
  `;


    

  const ModuleWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;

`;



export default function ServiceLine(){


  



    return(




 
              <TopicWrapper>
                <SLWrapper>
                      <p className="font-Kanit  ">
                      <span className="text-orange-600">F</span>ield <span className="text-orange-600">S</span>ervice <span className="text-orange-600">E</span>ngineering <span className='text-slate-300'>- Industrial Automation & </span><span className="text-orange-600">AI.</span> 
                      </p>
                    </SLWrapper>

<ModuleWrapper className=' flex flex-wrap'>
<div className=' px-4 font-EBGaramond text-slate-400 inline-flex py-1'><FB/> Food & Beverages </div>
<div className=' px-5 font-EBGaramond text-slate-400 inline-flex py-1 '><OilDrill></OilDrill> Oil & Gas</div>
<div className=' px-4 font-EBGaramond text-slate-400 inline-flex py-1'><Auto></Auto>Automotive Industry</div>
<div className=' px-4 font-EBGaramond text-slate-400 inline-flex py-1'><Cement/> Cement Industry</div>
<div className=' px-4 font-EBGaramond text-slate-400 inline-flex py-1'><Pharma/> Pharmaceutical Industry</div>
</ModuleWrapper>

  


  
  




  <DescriptionWrapper>
  <div className='text-center text-slate-300 font-EBGaramond'>Solving your industrial automation needs with our expert field service engineering: Offering our dedicated services in electrical installation and PLC programming for you to thrive.</div>
  </DescriptionWrapper>
  </TopicWrapper>


              





     );
 }
 
 