export default function Eth(){

    return (


<svg className="mb-5" fill="#474747" height="80px" width="80px" version="1.1" id="Layer_1" viewBox="0 0 503.607 503.607" >
<defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#cc2b5e" />
            <stop offset="100%" stopColor="#753a88" />
          </linearGradient>
        </defs>
        <g>
	<g>
		<path fill="url(#gradient)" d="M386.098,486.82H276.984V335.738h16.787c3.685,0,6.941-2.401,8.024-5.926l8.511-27.648h-33.322    c-4.642,0-8.393-3.76-8.393-8.393c0-4.642,3.752-8.393,8.393-8.393h38.484l5.162-16.787h-43.646c-4.642,0-8.393-3.76-8.393-8.393    c0-4.642,3.752-8.393,8.393-8.393H325.8l5.162-16.787h-53.978c-4.642,0-8.393-3.76-8.393-8.393c0-4.642,3.752-8.393,8.393-8.393    h58.754v-16.787h-58.754c-4.642,0-8.393-3.76-8.393-8.393c0-4.642,3.752-8.393,8.393-8.393h58.754v-75.541    c0-4.642-3.752-8.393-8.393-8.393V16.787c0-4.642-3.752-8.393-8.393-8.393h-25.18c0-4.642-3.752-8.393-8.393-8.393h-67.148    c-4.642,0-8.393,3.752-8.393,8.393h-25.18c-4.642,0-8.393,3.752-8.393,8.393v83.934c-4.642,0-8.393,3.752-8.393,8.393v75.541    h58.754c4.642,0,8.393,3.752,8.393,8.393c0,4.633-3.752,8.393-8.393,8.393h-58.754v16.787h58.754c4.642,0,8.393,3.752,8.393,8.393    c0,4.633-3.752,8.393-8.393,8.393h-53.978l5.162,16.787h48.816c4.642,0,8.393,3.752,8.393,8.393c0,4.633-3.752,8.393-8.393,8.393    h-43.646l5.162,16.787h38.484c4.642,0,8.393,3.752,8.393,8.393c0,4.633-3.752,8.393-8.393,8.393h-33.322l8.511,27.648    c1.083,3.525,4.339,5.926,8.024,5.926h16.787V486.82H117.508c-4.642,0-8.393,3.752-8.393,8.393c0,4.633,3.752,8.393,8.393,8.393    h117.508h33.574h117.508c4.642,0,8.393-3.76,8.393-8.393C394.492,490.572,390.74,486.82,386.098,486.82z M293.771,75.541V25.18    h16.787v75.541h-16.787h-16.787V83.934h8.393C290.019,83.934,293.771,80.174,293.771,75.541z M226.623,16.787h50.361v50.361    h-8.393c-4.642,0-8.393,3.752-8.393,8.393v25.18H243.41v-25.18c0-4.642-3.752-8.393-8.393-8.393h-8.393V16.787z M193.049,25.18    h16.787v50.361c0,4.633,3.752,8.393,8.393,8.393h8.393v16.787h-16.787h-16.787V25.18z M251.803,159.475    c-24.903,0-45.585-18.189-49.605-41.967h17.097c3.727,14.462,16.896,25.18,32.508,25.18s28.781-10.718,32.508-25.18h17.097    C297.388,141.287,276.707,159.475,251.803,159.475z"/>
	</g>
</g>
</svg>




    );
}


