import React from 'react';
import './ThreeBox.css';

export default function ThreeBox() {
  return (
<div className="Boxloader">
  <div className="boxload box-11">
    <div className="side-left text-[7px]">Predict</div>
    <div className="side-right"></div>
    <div className="side-top"></div>
  </div>
  <div className="boxload box-12">
    <div className="side-left"></div>
    <div className="side-right text-[7px]">& Extend</div>
    <div className="side-top"></div>
  </div>
  <div className="boxload box-13">
    <div className="side-left text-[7px]">Optimize</div>
    <div className="side-right"></div>
    <div className="side-top"></div>
  </div>
  <div className="boxload box-14">
    <div className="side-left text-[8px]"></div>
    <div className="side-right"></div>
    <div className="side-top"></div>
  </div>
</div>







  );
}
