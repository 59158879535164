export default function OnTime(){

    return (


<svg className="icon icon-tabler icon-tabler-clock-check" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M20.942 13.021a9 9 0 1 0 -9.407 7.967"></path>
   <path d="M12 7v5l3 3"></path>
   <path d="M15 19l2 2l4 -4"></path>
</svg>





    );
}


