export default function Speed(){

    return (


<svg className="icon icon-tabler icon-tabler-brand-speedtest" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M5.636 19.364a9 9 0 1 1 12.728 0"></path>
   <path d="M16 9l-4 4"></path>
</svg>





    );
}


