import React from 'react';
import styled from 'styled-components';
import './Cu.css';
import Image_Content from './Image_Content_Cu';

const ImageContainer = styled.div`
  position: relative;
  z-index: 1;
`;
const ContentContainer = styled.div`
  position: absolute;
 
  z-index: 2;

  /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    top:5%;
  }
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    top:5%;
  }
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    top:10%;
  }
  @media screen and (max-width: 631px) {
    top:15%;
  }
`;


const Image = styled.img`
/* Set your desired maximum width */
  z-index:1;
  height: auto;

  @media screen and (min-width: 1298px) and (max-width: 5500px) {
    width: 100%;
  }
  @media screen and (max-width: 1298px) {
    max-width: 2600px;
  }
`;
const ImageMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(48, 48, 53, 0.9);/* Adjust the alpha value for desired transparency */
`;

export default function Image_Cr() {
  return (
    <div className='container_As'>
      <ImageContainer>
        <Image src="./FSE.jpg" alt="FSE" />
        <ImageMask />
        <ContentContainer>
        <Image_Content></Image_Content>
        </ContentContainer>
      </ImageContainer>
      
    </div>
  );
}
