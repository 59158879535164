import Services_Section1 from "./Services_Section1"
import Services_Section2 from "./Services_Section2"
import styled from "styled-components";
import BottomPage from "../Bottom";
import { useEffect } from 'react';
import ScrollToTop1 from '../Utilities/ScrollToTop1';


const Overflow_Sr = styled.main`
// width: 100%;
// height: 100%;

Overflow-y: hidden;
Overflow-x: hidden;

`;
export default function Services() {
  // Automatically scroll to the top of the page - 

useEffect(() => {
  window.scrollTo(0, 0);
}, []);

 
  return (
    <Overflow_Sr>
      <Services_Section1></Services_Section1>
<Services_Section2></Services_Section2>
{/* <BottomPage></BottomPage> */}
<ScrollToTop1/>
    </Overflow_Sr>
  )
}

