import './AboutUs.css';
import styled from 'styled-components';
export default function Image_Content_As()
{



    return(




<div className="content_As">
              <div className="relative">
              <TopicWrapper_As> About Us </TopicWrapper_As>
              <Description_As className="font-Montserrat text-gray-300">
                At IntelliMeta, we are a dedicated team of Industrial Automation and AI professionals, passionate about
                empowering enterprises with the transformative power of AI-driven decision-making. With our expertise, we
                specialize in designing, developing, and deploying cutting-edge, customized real-time AI solution blended with 3D.
              </Description_As>
              <TopicWrapper_As> Our Vison </TopicWrapper_As>
              <Description_As className="font-Montserrat text-gray-300"> To help our customerts to focus solely on their core business while we keep them updated with the latest "new age" technologies, aiming to enhance productivity. Through our modern and user-friendly platform, we bring the latest innovations at your fingertips, enabling you to make informed decisions based on factual data. The AI capabilities of our platform seamlessly integrate with on-site machinery, providing an intuitive and predictive communication channel that goes beyond current and historical events. </Description_As>
              </div>
      </div>





    );

}


const TopicWrapper_As = styled.main`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Kanit;
    padding-top:10%;
    padding-bottom:10%;


    /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    font-size: 45px;
    padding-top:6%;
    padding-bottom:2%;
    
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    font-size: 45px;
    padding-top:6%;
    padding-bottom:2%;
  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    font-size: 40px;
    padding-top:16%;
    padding-bottom:10%;
    
  }
  
  @media screen and (max-width: 631px) {
    font-size: 30px;
    padding-top:18%;
    padding-bottom:10%;

  `;


  const Description_As = styled.main`

    font-family: Montserrat;



    /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    font-size: 20px;
    margin-top: 4%;
    
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    font-size: 18px;
    margin-top: 4%;
  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    font-size: 16px;
    margin-top: 4%;
  }

  @media screen and (min-width: 370px) and (max-width: 631px) {
    font-size: 17px;
    margin-top: 2%;
  }
  
  @media screen and (max-width: 631px) {
    font-size: 10px;
    margin-top: 2%;

  `;

