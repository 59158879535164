import React, { useLayoutEffect } from 'react'

import styled from 'styled-components'
import Canvas_Services from './Canvas_Services'
import { Gradient } from '../lib/Gradient.js'

import ServiceLine2 from './ServiceLine2.js'


export default function Services_Section2() {
  useLayoutEffect(() => {
    const gradient = new Gradient()
    gradient.initGradient('#gradient-canvas')
  }, [])

  return (
    
    
       <Main1_Sr>

                    
          <Main_Sr>
              <canvas id="gradient-canvas" data-transition-in />
                    <CanvasContainer_Sr>
                                      <Canvas_Services />
                    </CanvasContainer_Sr>
            </Main_Sr>
<CloudLineWrapper_Sr>
<ServiceLine2></ServiceLine2>
</CloudLineWrapper_Sr>
            
            </Main1_Sr>

                         

            

  
  )
}

const Main1_Sr = styled.main`
position: relative;
display: flex;
flex-direction: column;
padding-top :0%;

width: 100%;
height: auto;
 
`

const Main_Sr = styled.main`
position: relative;
display: flex;
flex-direction: row;
width: 100%;
height:auto;

@media only screen and (max-width: 1200px) {
  flex-direction: column;
  }
`



const CanvasContainer_Sr = styled.div`
position: relative;
flex: 1 1 0;
min-width: 0;
min-height: 700px;
padding-top: 0em;
padding-right: 0em;


@media screen and (min-width: 1440px) and (max-width: 3500px) {
  {
    transform: scale(1);
    order: -1;
    }
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
  {
    transform: scale(1);
    order: -1;
    }
  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
  {
    transform: scale(1);
    order: -1;
    }
  }
  
  @media screen and (min-width: 376px) and (max-width: 631px) {
  {
    
    transform: scale(1);
    order: -1;
    }
  }
  
  @media screen and (max-width: 376px) {
  {
    
    transform: scale(1);
    order: -1;
    }
  }
















`


const CloudLineWrapper_Sr = styled.main`
position: relative;
margin-top: 0%;
margin-bottom: 0%;

`;