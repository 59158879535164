import React, { useLayoutEffect } from 'react'

import styled from 'styled-components'

import Saving from '../svgs/Saving.js'
import Sustain from '../svgs/Sustain.js'
import Netzero from '../svgs/Netzero.js'
import Compliance from '../svgs/Compliance.js'
import Reports from '../svgs/Reports.js'







export default function Module3Card() {
  

  return (
    

<div>
    <ModuleHeading className='text-3xl font-bold font-Montserrat tracking-tight'> Accelerate your path to net zero!! </ModuleHeading>

    
        <ModuleWrapper>

                       

{/* Module 1 - Intelliscope -  Remote Asset Monitoring -----------------------------------------------------------------------*/}

                                <div>
                                <div className='ModuleCard'> 
                        <div className='relative py-4 text-center '> Energy Optimization </div>
                    <div>
                    <div className="font-Montserrat text-xs text-black p-2 flex items-center">
                                                      <span className="ml-2"><Saving/></span>
                                                      <span className="ml-2">Cost Saving</span>
                                                    </div>
                                                    <div className="font-Montserrat text-xs text-black p-2 flex items-center">
                                                      <span className="ml-2"><Reports/></span>
                                                      <span className="ml-2">Energy Consumption Analytics</span>
                                                    </div>
                                                    <div className="font-Montserrat text-xs text-black p-2 flex items-center">
                                                      <span className="ml-2 text-green-600"><Netzero/></span>
                                                      <span className="ml-2">Net Zero Emission Target</span>
                                                    </div>
                                                    <div className="font-Montserrat text-xs text-black p-2 flex items-center">
                                                      <span className="ml-2"><Sustain/></span>
                                                      <span className="ml-2">Environment Sustainability</span>
                                                    </div>
                                                    <div className="font-Montserrat text-xs text-black p-2 flex items-center">
                                                      <span className="ml-2"><Compliance/></span>
                                                      <span className="ml-2">Regulatory Compliances</span>
                                                    </div> 
                                                              </div>
                                    </div>
                                </div>    

{/*SLASH Module 1 - Intelliscope -  Remote Asset Monitoring -----------------------------------------------------------------------*/}


                                <div>                                
                                      {/* <div className="vertical-line"></div> */}

                                      <div>
                                        <ModuleContent className=' text-slate-700'>
                                        <VerticalL3 className='text-white'>.</VerticalL3>
                                        Optimize energy consumption to decrease environmental impact and generate substantial cost savings. Utilize advanced energy consumption analytics to gain insight into your energy usage patterns and to identify improvement opportunities. Align without difficulty with net-zero emission goals and contribute to a sustainable future. Implement our custom-tailored solution to ensure environmental sustainability and regulatory compliance.
 </ModuleContent></div>
                                        
                                        
                                      
                                  </div>      

          </ModuleWrapper>
    </div>
  
  )
}

const ModuleHeading = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
  background: linear-gradient(174deg, #0c96f1, #1623db, #d1082d);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            /* Media Queries */
            @media screen and (min-width: 1440px) and (max-width: 3500px) {
              padding-top: 1%;
              padding-bottom: 1%;
            }
            
            @media screen and (min-width: 1000px) and (max-width: 1440px) {
              padding-top: 1%;
              padding-bottom: 2%;
            }
            
            @media screen and (min-width: 631px) and (max-width: 1000px) {
              padding-top: 5%;
              padding-bottom: 5%;
            }
            
            @media screen and (max-width: 631px) {
              padding-top: 5%;
              padding-bottom: 5%;
            }
  
`;
const ModuleWrapper = styled.main`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  

  /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    flex-direction: row;
    text-align: left;
    margin-left: 5%;
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    flex-direction: row;
    margin-left: 5%;
  
  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    flex-direction: column;
   
  }
  
  @media screen and (max-width: 631px) {
    flex-direction: column;
    
  }
  
`;




const ModuleContent = styled.main`
  position: relative;
  display: flex;
  align-items: center;
  width:90%;

 

  /* Media Queries */

  @media screen and (min-width: 2001px) {
    font-size: 23px;

  @media screen and (min-width: 1440px) and (max-width: 2000px) {
    font-size: 23px;
    padding-right: 15%;
  }


  @media screen and (min-width: 1440px) and (max-width: 2000px) {
    font-size: 23px;
    padding-right: 15%;
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    font-size: 20px;
  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    font-size: 18px;
    margin-left:5%; 
  }
  
  @media screen and (max-width: 631px) {
    padding-top:15%;
    margin:10%;
    font-size: 8px;
  
  
`;

const VerticalL3 = styled.main`
 {
width: 5px;
background-color: #fb8c00; 
margin-left:5%;
margin-right:5%;

@media screen and (min-width: 1440px) and (max-width: 3500px) {
  height: 255px;
}
@media screen and (min-width: 1000px) and (max-width: 1440px) {
  height: 255px;
}
@media screen and (min-width: 631px) and (max-width: 1000px) {
  height: 255px;
}
@media screen and (min-width: 450px) and (max-width: 631px) {
  height: 355px;
}
@media screen and (min-width: 400px) and (max-width: 450px) {
  height: 290px;
}
@media screen and (max-width: 400px) {
  height: 300px;

`








