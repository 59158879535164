import React from 'react';
import Android_fmcg from './Android_fmcg'
import IOS_fmcg from './IOS_fmcg'



const FMCG_Industry = () => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);


  if (isIOS) {
    // Render iOS-specific content
    return (
      <IOS_fmcg></IOS_fmcg>
    ) ;
  } else {
    // Render content for other devices or browsers
    return (
   <Android_fmcg></Android_fmcg>
    );
    
  }
};

export default FMCG_Industry;
