
import BottomPage from "../Bottom";
import './Cr.css';
import Image_Cr from "./Image_Cr";
import styled from "styled-components";
import React from 'react';
import CareerLine from "./CareerLine";
import { useEffect } from 'react';
import ScrollToTop1 from '../Utilities/ScrollToTop1';



const Overflow_Cr = styled.main`
// width: auto;
// height: 100%;

Overflow-y: hidden;
Overflow-x: hidden;
`;


export default function Career() {


 
useEffect(() => {
  window.scrollTo(0, 0);
}, []);


  return (
<Overflow_Cr>
  <CareerLine/>

    <Image_Cr/>

  {/* <BottomPage></BottomPage> */}
  <ScrollToTop1/>
</Overflow_Cr>
    
  );
}
