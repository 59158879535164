import React, { useLayoutEffect } from 'react'

import styled from 'styled-components'




import Data from '../svgs/Data.js'

import Supervision from '../svgs/Supervision.js'
import Speed from '../svgs/Speed.js'

import Production from '../svgs/Production.js'

import Focus from '../svgs/Focus.js'







export default function Module2Card() {
  

  return (
    

<div>
    <ModuleHeading className='text-3xl font-bold font-Montserrat tracking-tight'> Asset monitoring for proactive insights!! </ModuleHeading>

    
        <ModuleWrapper>

                       

{/* Module 1 - Intelliscope -  Remote Asset Monitoring -----------------------------------------------------------------------*/}

                                <div>
                                <div className='ModuleCard'> 
                        <div className='relative py-4 text-center '> Plant 3D Monitoring</div>
                    <div>
                    <div className="font-Montserrat text-xs text-black  p-2 rounded-lg flex items-center">
                                                        <span className="ml-2 text-cyan-400"><Supervision/></span>
                                                        <span className="ml-2">Remote Supervision</span>
                                                      </div>
                                                      <div className="font-Montserrat text-xs text-black  p-2 rounded-lg flex items-center">
                                                        <span className="ml-2"><Speed/></span>
                                                        <span className="ml-2">Real-time visibility</span>
                                                      </div>
                                                      <div className="font-Montserrat text-xs text-black  p-2 rounded-lg flex items-center">
                                                        <span className="ml-2"><Data/></span>
                                                        <span className="ml-2">Data-Driven Decision Making</span>
                                                      </div>
                                                      <div className="font-Montserrat text-xs text-black  p-2 rounded-lg flex items-center">
                                                        <span className="ml-2"><Production/></span>
                                                        <span className="ml-2">Improved Production Planning</span>
                                                      </div>
                                                      <div className="font-Montserrat text-xs text-black  p-2 rounded-lg flex items-center">
                                                        <span className="ml-2"><Focus/></span>
                                                        <span className="ml-2">Proactive Supply Chain Management</span>
                                                      </div> 
                                                              </div>
                                    </div>
                                </div>    

{/*SLASH Module 1 - Intelliscope -  Remote Asset Monitoring -----------------------------------------------------------------------*/}


                                <div>                                
                                      {/* <div className="vertical-line"></div> */}

                                      <div>
                                        <ModuleContent className=' text-slate-700'>
                                        <VerticalL2 className='text-white'>.</VerticalL2>
                                        Enjoy asset monitoring like never before. Gain real-time insight into your assets, enabling you to superintend and monitor their performance remotely. Utilize data-driven decision-making to improve production planning and optimize operations. With proactive supply chain management, it is possible to anticipate and resolve potential problems, thereby assuring seamless operations and minimizing disruptions.
                                          </ModuleContent></div>
                                        
                                        
                                      
                                  </div>      

          </ModuleWrapper>
    </div>
  
  )
}

const ModuleHeading = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;

  background: linear-gradient(174deg, #0c96f1, #1623db, #d1082d);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
  
            /* Media Queries */
            @media screen and (min-width: 1440px) and (max-width: 3500px) {
              padding-top: 1%;
              padding-bottom: 1%;
            }
            
            @media screen and (min-width: 1000px) and (max-width: 1440px) {
              padding-top: 1%;
              padding-bottom: 2%;
            }
            
            @media screen and (min-width: 631px) and (max-width: 1000px) {
              padding-top: 5%;
              padding-bottom: 5%;
            }
            
            @media screen and (max-width: 631px) {
              padding-top: 5%;
              padding-bottom: 5%;
            }
            
          `;
const ModuleWrapper = styled.main`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  

  /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    flex-direction: row;
    text-align: left;
    margin-left: 5%;
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    flex-direction: row;
    margin-left: 5%;
  
  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    flex-direction: column;
   
  }
  
  @media screen and (max-width: 631px) {
    flex-direction: column;
    
  }
  
`;




const ModuleContent = styled.main`
  position: relative;
  display: flex;
  align-items: center;
  width:90%;

 

  /* Media Queries */

  @media screen and (min-width: 2001px) {
    font-size: 23px;

  @media screen and (min-width: 1440px) and (max-width: 2000px) {
    font-size: 23px;
    padding-right: 15%;
  }


  @media screen and (min-width: 1440px) and (max-width: 2000px) {
    font-size: 23px;
    padding-right: 15%;
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    font-size: 20px;
  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    font-size: 18px;
    margin-left:5%; 
  }
  
  @media screen and (max-width: 631px) {
    padding-top:15%;
    margin:10%;
    font-size: 8px;
  
`;

const VerticalL2 = styled.main`
 {
width: 5px;
background-color: #fb8c00; 
margin-left:5%;
margin-right:5%;

@media screen and (min-width: 1440px) and (max-width: 3500px) {
  height: 255px;
}
@media screen and (min-width: 1000px) and (max-width: 1440px) {
  height: 255px;
}
@media screen and (min-width: 631px) and (max-width: 1000px) {
  height: 255px;
}
@media screen and (min-width: 450px) and (max-width: 631px) {
  height: 200px;
}
@media screen and (min-width: 400px) and (max-width: 450px) {
  height: 290px;
}
@media screen and (max-width: 400px) {
  height: 290px;
}

`








