import { useRef } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { AsciiRenderer,Float  } from '@react-three/drei'
import Ypad from './Ypad'
import { useControls } from "leva"



export default function Canvas_Oil() {

  
  return (
    <Canvas >
      
      {/* <color attach="background" args={['black']} /> */}
      <spotLight position={[10, 12, 10]} angle={0.08} penumbra={1} />
      <pointLight position={[-0.16, 7.06, 7.64]} />
      <Float
  speed={1} // Animation speed, defaults to 1
  rotationIntensity={0.3} // XYZ rotation intensity, defaults to 1
  floatIntensity={0.3} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
  floatingRange={[0.3,0.6]}  // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
>
        <Ypad position={[0.29, -1.75, 5.0]} rotation = {[0.35, 0.12, 0.03]} scale={0.1}/>
      </Float>
      {/* <Images position={ [0.39, 0.63, 3.05]}  rotation={ [-0.57, -0.08, 0.03]} scale={[1.40, 0.68, 0.71]}/> */}
      </Canvas>
  );
}

