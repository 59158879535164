export default function RemoteMonitoring(){

    return (


<svg className="icon icon-tabler icon-tabler-building-broadcast-tower  text-orange-700" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
   <path d="M16.616 13.924a5 5 0 1 0 -9.23 0"></path>
   <path d="M20.307 15.469a9 9 0 1 0 -16.615 0"></path>
   <path d="M9 21l3 -9l3 9"></path>
   <path d="M10 19h4"></path>
</svg>
    );
}


