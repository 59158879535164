export default function Colab()
{

    return(




<svg fill="#474747" width="80px" height="80px" viewBox="0 0 36 36" version="1.1" preserveAspectRatio="xMidYMid meet">
<defs>
<linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#cc2b5e" />
            <stop offset="100%" stopColor="#753a88" />
          </linearGradient>
        </defs>
    <title>update-line</title>
	<path fill="url(#gradient)" d="M19.5,28.1h-2.9c-0.5,0-0.9-0.3-1-0.8l-0.5-1.8c-0.2-0.1-0.2-0.1-0.4-0.2l-1.6,0.9c-0.4,0.2-0.9,0.2-1.2-0.2l-2.1-2.1     c-0.3-0.3-0.4-0.8-0.2-1.2l0.9-1.6c-0.1-0.2-0.1-0.2-0.2-0.4l-1.8-0.5c-0.4-0.1-0.8-0.5-0.8-1v-2.9c0-0.5,0.3-0.9,0.8-1l1.8-0.5     c0.1-0.2,0.1-0.2,0.2-0.4l-0.9-1.6c-0.2-0.4-0.2-0.9,0.2-1.2l2.1-2.1c0.3-0.3,0.8-0.4,1.2-0.2l1.6,0.9c0.2-0.1,0.2-0.1,0.4-0.2     l0.5-1.8c0.1-0.4,0.5-0.8,1-0.8h2.9c0.5,0,0.9,0.3,1,0.8l0.5,1.8c0.2,0.1,0.2,0.1,0.4,0.2l1.6-0.9c0.4-0.2,0.9-0.2,1.2,0.2     l2.1,2.1c0.3,0.3,0.4,0.8,0.2,1.2l-0.9,1.6c0.1,0.2,0.1,0.2,0.2,0.4l1.8,0.5c0.4,0.1,0.8,0.5,0.8,1v2.9c0,0.5-0.3,0.9-0.8,1     l-1.8,0.5c-0.1,0.2-0.1,0.2-0.2,0.4l0.9,1.6c0.2,0.4,0.2,0.9-0.2,1.2l-2.1,2.1c-0.3,0.3-0.8,0.4-1.2,0.2l-1.6-0.9     c-0.2,0.1-0.2,0.1-0.4,0.2l-0.5,1.8C20.3,27.8,19.9,28.1,19.5,28.1z M17.3,26.1h1.4l0.5-2.1l0.5-0.2c0.4-0.1,0.7-0.3,1.1-0.4     l0.5-0.3l1.9,1.1l1-1l-1.1-1.9l0.3-0.5c0.2-0.3,0.3-0.7,0.4-1.1l0.2-0.5l2.1-0.5v-1.4L24,16.8l-0.2-0.5c-0.1-0.4-0.3-0.7-0.4-1.1     l-0.3-0.5l1.1-1.9l-1-1l-1.9,1.1l-0.5-0.3c-0.3-0.2-0.7-0.3-1.1-0.4L19.2,12l-0.5-2.1h-1.4L16.8,12l-0.5,0.2     c-0.4,0.1-0.7,0.3-1.1,0.4l-0.5,0.3l-1.9-1.1l-1,1l1.1,1.9l-0.3,0.5c-0.2,0.3-0.3,0.7-0.4,1.1L12,16.8l-2.1,0.5v1.4l2.1,0.5     l0.2,0.5c0.1,0.4,0.3,0.7,0.4,1.1l0.3,0.5l-1.1,1.9l1,1l1.9-1.1l0.5,0.3c0.3,0.2,0.7,0.3,1.1,0.4l0.5,0.2L17.3,26.1z M27.1,19.5     L27.1,19.5L27.1,19.5z"/>
	<path fill="url(#gradient)" d="M18,22.3c-2.4,0-4.3-1.9-4.3-4.3s1.9-4.3,4.3-4.3c2.4,0,4.3,1.9,4.3,4.3S20.4,22.3,18,22.3z M18,15.7     c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3S19.3,15.7,18,15.7z"/>
	<path fill="url(#gradient)" d="M18,2c-0.6,0-1,0.4-1,1s0.4,1,1,1c7.7,0,14,6.3,14,14s-6.3,14-14,14S4,25.7,4,18c0-2.8,0.8-5.5,2.4-7.8v1.2   c0,0.6,0.4,1,1,1s1-0.4,1-1v-5h-5c-0.6,0-1,0.4-1,1s0.4,1,1,1h1.8C3.1,11.1,2,14.5,2,18c0,8.8,7.2,16,16,16s16-7.2,16-16   S26.8,2,18,2z"/>
    <rect width="36" height="36" fillOpacity="0"/>
</svg>



    );
}