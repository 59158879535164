import React, { useLayoutEffect } from 'react'
import styled from 'styled-components'
import Canvas_Product from './Canvas_Product.js'
import { Gradient } from '../lib/Gradient.js'
import CloudLine from './CloudLine.js'




export default function IOS_Ds() {


  return (
    
    
<div>
  
            <TagLine>
          <div className='uppercase tracking-wider font-Montserrat font-bold text-b text-orange-600 mt-[2%]'>A 3D visualisation platform powered by data to transform your manufacturing operations.</div>
          </TagLine>
  
          
              <CanvasContainer>
                                  <Canvas_Product/>
                </CanvasContainer>

                <ContentContainer>
                  <Content>
                  
                                  <h2 className='font-Kanit text-5xl text-slate-900  py-10'> Cloudline</h2>
                                  <h1 className='font-EBGaramond text-slate-700 '> A real-time distributed software application ingeniously ingests data from sensors, PLCs, flat files, ERPs, databases, and any streaming data source, creating unparalleled situational awareness. With a relentless focus on reducing operational costs, Cloudline revolutionizes overall operational efficiency. It empowers your organization with data-driven decision-making capabilities, enabling you to optimize every aspect of your manufacturing processes.</h1>
                                  <h1 className='font-EBGaramond text-slate-700 py-4 '>Say goodbye to manual data processing and welcome a new era of streamlined operations and improved performance. Embrace Cloudline today and embark on a transformative journey towards unparalleled manufacturing excellence.</h1>

                  </Content>
                                   

                </ContentContainer>
                <CloudLine></CloudLine>
                </div>





                     


  
  )
}

const Main1 = styled.main`
position: relative;
display: flex;
flex-direction: column;
padding-top :0%;
margin-bottom: 5%;
width: 100%;
height: 100%;
`

const Main = styled.main`
position: relative;
display: flex;
flex-direction: row;
width: 100%;
height:100%;

@media only screen and (max-width: 1200px) {
  flex-direction: column;
  }



`
const TagLine = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Add align-items property to center horizontally and vertically */



  /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    padding-bottom: 15px;
    padding-top: 100px;
    font-size: 1.2rem;
  }

  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    padding-bottom: 15px;
    padding-top: 100px;
    font-size: 1rem;
  }

  @media screen and (min-width: 800px) and (max-width: 1000px) {
    padding-top: 120px;
    font-size: 0.9rem;
    text-align: center;

  }
  @media screen and (min-width: 500px) and (max-width: 800px) {
    padding-top: 110px;
    font-size: 0.9rem;
    text-align: center;
  }

    @media screen and (min-width: 380px) and (max-width: 500px) {
    padding-top: 70px;
    font-size: 0.9rem;
    text-align: center;
  }

  @media screen and (max-width: 500px) {
    padding-top: 70px;
    font-size: 0.7rem;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
}
`

const CanvasContainer = styled.div`
position: relative;
flex: 1 1 0;
width: 100vw;
height: 50vh;
padding-top: 3em;



`

const ContentContainer = styled.div`
position: relative;
flex: 1 1 0;
  min-width: 0;
  min-height: 500;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  @media only screen and (max-width: 1200px) {
    margin-top: 0;
  }
`
const Content = styled.div`
  flex: 1;
  padding-left: 4em;

  h2 {
    // color: #f7057e;
    font-size: 4rem;
    margin-top: 1.9em;
    padding: 0;
    line-height: 0;
    margin-bottom: 1.2em;
    white-space: nowrap;
  }

  h3 {
    float: right;
    text-align: right;
    width: 100px;
    font-size: 0.8rem;
  }

 

  p {
    font-size: 0.8rem;
    width: 200px;
  }

  @media only screen and (max-width: 3500px) {
    width: 100%;
    padding-right: 2em;
    padding-left: 2em;
    margin-bottom:5%;
    h1 {
      font-size: 1.5rem;
      margin-bottom: 5%;
      
    }

    h2 {
      font-size: 2.3rem;
      line-height: 2.3rem;
      margin-bottom: 0.8rem;
    }
  }

  @media only screen and (max-width: 1200px) {
    width: 100%;
    padding-right: 2em;
    padding-left: 2em;
    margin-bottom:5%;
    h1 {
      font-size: 1.2rem;
      margin-bottom: 5%;
      
    }

    h2 {
      font-size: 2.3rem;
      line-height: 2.3rem;
      margin-bottom: 0.8rem;
    }
  }

  @media only screen and (max-width: 800px) {
    h1 {
      font-size: 1.2rem;
      text-align: center;
      margin-bottom: 5%;
    }

    h2 {
      font-size: 1.6rem;
      text-align: center;
    }
  }

  @media only screen and (max-width: 600px) {
    h1 {
      font-size: 1.2rem;
      
      padding-bottom: 1.5em;
      text-align: center;
    }

    h2 {
      font-size: 1.3rem;
      line-height: 1.3rem;
      text-align: center;
    }
  }
`
const CloudLineWrapper = styled.main`
position: relative;
margin-top: 0%;
margin-bottom: 0%;

`;