import { createRoot } from 'react-dom/client'
import './styles.css'
import App from './App1'



createRoot(document.getElementById('root')).render(<App/>)



