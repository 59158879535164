import React, { useState } from 'react';

const ContactUs = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [title, setTitle] = useState('');
  const [country, setCountry] = useState('');
  const [option, setOption] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState("Send Message");
  

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleOptionChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setOption((prevOptions) => [...prevOptions, value]);
    } else {
      setOption((prevOptions) => prevOptions.filter((option) => option !== value));
    }
    console.log("defining option value",option);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
  
    const { email, firstName, message, lastName, company, title, country, option } = e.target.elements;
    let details = {
      title: title.value,
      name: firstName.value,
      email: email.value,
      company: company.value,
      country: country.value,
      message: message.value,
      option: option,
    };
  
    let isRequestTimedOut = false;
    const timeout = setTimeout(() => {
      isRequestTimedOut = true;
      setStatus("Sending failed");
    }, 5000);
  
    let response = await fetch("http://localhost:5000/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
  
    clearTimeout(timeout);
  
    if (!isRequestTimedOut) {
      setStatus("Send Message");
      let result = await response.json();
      alert(result.status);
      console.log({
        email,
        firstName,
        lastName,
        company,
        title,
        country,
        option,
        message,
      });
  
      setEmail("");
      setFirstName("");
      setLastName("");
      setCompany("");
      setTitle("");
      setCountry("");
      setOption("");
      setMessage("");
    }
  };
  




  const countries = [
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bhutan',
    'Bolivia',
    'Bosnia and Herzegovina',
    'Botswana',
    'Brazil',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'Colombia',
    'Comoros',
    'Congo',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'East Timor',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Eswatini',
    'Ethiopia',
    'Fiji',
    'Finland',
    'France',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Greece',
    'Grenada',
    'Guatemala',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Korea, North',
    'Korea, South',
    'Kosovo',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Micronesia',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Morocco',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'North Macedonia',
    'Norway',
    'Oman',
    'Palau',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Qatar',
    'Romania',
    'Russia',
    'Rwanda',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Togo',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City',
    'Venezuela',
    'Vietnam',
    'Yemen',
    'Zambia',
    'Zimbabwe',
  ];








  return (
    <div>
     
      <form onSubmit={handleSubmit}>

{/* ___________________Email__________________________________________________________________________________ */}

        <div>
		
          <label htmlFor="email">Email:<span className='text-orange-600'>*</span></label>
		  <div className="  mt-0 mb-3 text-lg font-medium w-[560px]">
          <input
            type="email"
            id="email"
            placeholder="Company Email"
            value={email}
            onChange={handleEmailChange}
            required
			className="w-[70%] px-4 py-2 rounded  text-black bg-gray-300"
          />
		  
          </div>


          {/* ___________________Name__________________________________________________________________________________ */}
        </div>

        <div>
          <label htmlFor="firstName">Name:<span className='text-orange-600'>*</span></label>
		  <div className=" mt-0 mb-3 text-lg font-medium">
          <input
            type="text"
            id="firstName"
            placeholder="Name"
            value={firstName}
            onChange={handleFirstNameChange}
            required
			className="w-[70%] px-4 py-2 rounded text-black bg-gray-300"
          />
          </div>
        </div>

  {/* ________________Company_____________________________________________________________________________________ */}


        <div>
          <label htmlFor="company">Company:</label>
          <div className="  mt-0 mb-3 text-lg font-medium">
          <input
            type="text"
            id="company"
            placeholder="Company"
            value={company}
            onChange={handleCompanyChange}
            
            className="w-[70%] px-4 py-2 rounded text-black bg-gray-300"
          />
        </div>


        {/* ___________________Title__________________________________________________________________________________ */}
        </div>


        <div>
          <label htmlFor="title">Title:</label>
          <div className="  mt-0 mb-3 text-lg font-medium">
          <input
            type="text"
            id="title"
            placeholder="Title"
            value={title}
            onChange={handleTitleChange}
            
            className="w-[70%] px-4 py-2 rounded text-black bg-gray-300"
          />
        </div>
        </div>



{/* ___________________Country_________________________________________________________________________________ */}
        <div>
        <label htmlFor="country">Country:</label>
          <div className=" mt-0 mb-3 text-lg font-medium">
            <select
              id="country"
              value={country}
              onChange={handleCountryChange}
              
              className="w-[70%] px-4 py-2 rounded text-black bg-gray-300"
            >
              <option value="">Select Country</option>
              {countries.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div>
        </div>

   




{/* ___________________Options__________________________________________________________________________________ */}



        <div className='text-black bg-[#f15b04] font-bold rounded-lg px-2 mt-6 py-5 my-2 w-[395px]'>
  <label className='text-white' >Select:</label>
  <label>
    <div className=" shadow-md mt-2 mb-3 text-lg font-bold ">
      <input
        type="checkbox"
        value="Field Service Engineer"
        checked={option.includes('Field Service Engineer')}
        onChange={handleOptionChange}
      /> Field Service Engineer
    </div>
  </label>
  <label>
    <div className="shadow-md  mt-0 mb-0 text-lg font-bold ">
      <input
        type="checkbox"
        value="CloudLine Smart Factory"
        checked={option.includes('CloudLine Smart Factory')}
        onChange={handleOptionChange}
      /> CloudLine Smart Factory
    </div>
  </label>
</div>



        <div>
          <label htmlFor="message">Message:</label>
          <div className="  mt-0 mb-3 text-lg font-medium">
          <textarea
            id="message"
            placeholder="Enter your message (0-180 words)"
            value={message}
            onChange={handleMessageChange}
            
            className="w-[70%] px-4 py-2 rounded text-black bg-gray-300"
          ></textarea>
        </div>
        </div>
        <button 
        className=" bg-white border-2 border-orange-600 rounded-md py-2 px-6 text-orange-600 font-bold shadow-md hover:bg-orange-600 hover:text-white transition duration-300 ease-in-out"
         type="submit">{status}</button>
      </form>
    </div>
  );
};

export default ContactUs;
