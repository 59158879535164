import * as THREE from 'three';
import { useState, useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { Html } from '@react-three/drei';

export default function Notification({ onStateChange, children, ...props }) {


  const [isDivVisible, setDivVisible] = useState(true);

  const ref = useRef();
  const [isOccluded, setOccluded] = useState();
  const [isInRange, setInRange] = useState();
  const isVisible = isInRange && !isOccluded;
  const vec = new THREE.Vector3();

  useFrame((state) => {
    const range = state.camera.position.distanceTo(ref.current.getWorldPosition(vec)) <= 3000;
    if (range !== isInRange) setInRange(range);

    
  
  
  });


  const handleNotificationClick = (event) => {
  
    setDivVisible(false);
    //  console.log("I'm clicked");
     onStateChange(true)
     event.stopPropagation();
     
    
    
    };


  return (
    <group ref={ref}>


      
      <Html className='z-0'
       
        transform
        occlude
        onOcclude={setOccluded}
        style={{ transition: 'all 0.2s', opacity: isVisible ? 1 : 0, transform: `scale(${isVisible ? 1 : 0.25})` }}
        {...props}
          >
            <div
 onClick={handleNotificationClick} 
 >
      {isDivVisible && (
        
       <div className="alert warning -top-[300px] left-[0px] scale-90 z-0"
         onClick={handleNotificationClick} 
        >
          <div className="font-Kodcha text-center pt-5 top-10 font-bold text-white">3D Interactive Zone</div>
          <div className="font-Kodcha text-center text-xs  text-white">Click to interact</div>
        </div>
      )}
     
    </div>

        {children}
      </Html>
    </group>
  );
}
