export default function FMCG_MTTR(){

    return (


        <svg fill="#474747" height="80px" width="80px" version="1.1" id="Layer_1" viewBox="0 0 256 173">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#cc2b5e" />
            <stop offset="100%" stopColor="#753a88" />
          </linearGradient>
        </defs>
        <path fill="url(#gradient)" d="M127.3,56.9c15.2,0,27.4-12.2,27.4-27.4S142.5,2,127.3,2c-15.2,0-27.4,12.2-27.4,27.4C99.9,44.7,112.2,56.9,127.3,56.9z M63.6,136.3H14.3c-16,0-16.9-24.4,0.3-24.4h42.7l24.5-36.1C89,66,97.3,61.3,108.9,61.3h36.5c11.7,0,19.9,4.3,27.1,14.6l24.6,36.1h43c17.2,0,16.2,24.4,0.6,24.4h-49.3c-3.9,0-8.6-1.4-11.4-5.6l-18.8-26.8l-0.1,67.2H93.8l-0.1-67.2l-18.8,26.8C72.2,134.9,67.5,136.3,63.6,136.3z"/>
        <path fill="url(#gradient)" d="M223.5,41C207.2,41,194,54.2,194,70.5c0,16.3,13.2,29.5,29.5,29.5S253,86.8,253,70.5C253,54.2,239.7,41,223.5,41z M223.5,94.2c-13.1,0-23.7-10.6-23.7-23.7s10.6-23.7,23.7-23.7s23.7,10.6,23.7,23.7C247,83.5,236.4,94.2,223.5,94.2z M238.2,64.7c-0.5-0.9-1.7-1.5-3-0.9l-10.5,4.7V56.2c0-0.7-0.2-1.3-0.7-1.7l0,0c-0.4-0.4-0.9-0.5-1.5-0.5c-1.1,0-2.3,0.9-2.3,2.3v16.2c0,0.9,0.5,1.7,1.3,2.1c0.4,0.2,0.8,0.3,1.1,0.3c0.4,0,0.8-0.1,1.1-0.3l13.5-6.5c0.6-0.3,1-0.8,1.2-1.4C238.6,66,238.5,65.3,238.2,64.7L238.2,64.7z"/>
        <path fill="url(#gradient)" d="M57,85.8c-1.5-1.4-3.3-2-5.4-2c-0.7,0-1.4,0.2-2.1,0.4l-10.5-9.6l6.8-8l10.8,9.5L66,65.3l-27-23.7l-9.5,10.8L40,61.6l-7,7.6l-9.6-8.8c0.2-0.7,0.2-1.4,0.2-2.1c-0.1-2.1-0.9-3.9-2.4-5.3c-1.5-1.4-3.3-2-5.4-2c-0.7,0-1.5,0.2-2.1,0.4l6.1,5.5l-5.4,5.9l-6.1-5.5c-0.2,0.7-0.2,1.5-0.2,2.1c0.1,2.1,0.9,3.8,2.4,5.2c1.5,1.4,3.3,2,5.4,2c0.7,0,1.5-0.2,2.3-0.5l9.3,8.5l-15.4,16c-1.1,0.9-1.6,2.2-1.6,3.6c0,2.6,2.1,4.7,4.7,4.7c1.6,0,3-0.8,3.9-2l14.8-16.4l8.8,8l1.5,1.4c-0.2,0.7-0.2,1.5-0.2,2.3c0.1,2.1,0.9,3.8,2.4,5.3c1.5,1.4,3.3,2,5.4,2c0.7,0,1.4-0.2,2.1-0.4l-6.1-5.5l5.4-5.9l6,5.6c0.2-0.7,0.2-1.5,0.2-2.1C59.4,88.9,58.6,87.2,57,85.8z"/>
        <g id="shopping_cart">
        </g>
        <g id="cross">
        </g>
        <g id="leaf">
        </g>
      </svg>





    );
}



{/* <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-square-rounded-number-3-filled" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M12 2l.642 .005l.616 .017l.299 .013l.579 .034l.553 .046c4.687 .455 6.65 2.333 7.166 6.906l.03 .29l.046 .553l.041 .727l.006 .15l.017 .617l.005 .642l-.005 .642l-.017 .616l-.013 .299l-.034 .579l-.046 .553c-.455 4.687 -2.333 6.65 -6.906 7.166l-.29 .03l-.553 .046l-.727 .041l-.15 .006l-.617 .017l-.642 .005l-.642 -.005l-.616 -.017l-.299 -.013l-.579 -.034l-.553 -.046c-4.687 -.455 -6.65 -2.333 -7.166 -6.906l-.03 -.29l-.046 -.553l-.041 -.727l-.006 -.15l-.017 -.617l-.004 -.318v-.648l.004 -.318l.017 -.616l.013 -.299l.034 -.579l.046 -.553c.455 -4.687 2.333 -6.65 6.906 -7.166l.29 -.03l.553 -.046l.727 -.041l.15 -.006l.617 -.017c.21 -.003 .424 -.005 .642 -.005zm1 5h-2l-.15 .005a2 2 0 0 0 -1.85 1.995a1 1 0 0 0 1.974 .23l.02 -.113l.006 -.117h2v2h-2l-.133 .007c-1.111 .12 -1.154 1.73 -.128 1.965l.128 .021l.133 .007h2v2h-2l-.007 -.117a1 1 0 0 0 -1.993 .117a2 2 0 0 0 1.85 1.995l.15 .005h2l.15 -.005a2 2 0 0 0 1.844 -1.838l.006 -.157v-2l-.005 -.15a1.988 1.988 0 0 0 -.17 -.667l-.075 -.152l-.019 -.032l.02 -.03a2.01 2.01 0 0 0 .242 -.795l.007 -.174v-2l-.005 -.15a2 2 0 0 0 -1.838 -1.844l-.157 -.006z" stroke-width="0" fill="currentColor"></path>
</svg> */}