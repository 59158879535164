import React, { useRef, useState } from "react";
import { useGLTF,Html } from "@react-three/drei";





export default function Ypad(props)
{
    // const { nodes, materials } = useGLTF("./models/CompleteStation.glb");
    const Oilpad = useGLTF("./Ypad.glb");
    

  return (
    


    <primitive {...props} object={Oilpad.scene}   >
      
     

    </primitive>
    




    
   
  );
}

