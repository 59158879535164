
import styled from 'styled-components'

const TopicWrapper = styled.main`
position: relative;
display: flex;
flex-direction: column;
align-items: center;

/* Media Queries */
@media screen and (min-width: 1440px) and (max-width: 3500px) {
font-size: 35px;
padding-top:30px;

}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
font-size: 35px;
padding-top:30px;
}

@media screen and (min-width: 631px) and (max-width: 1000px) {
padding-top:20px;
font-size: 25px;
padding-bottom:15px;
}

@media screen and (max-width: 631px) {
padding-top:10px;
font-size: 25px;
padding-bottom:15px;

}
`;


export default function ModuleLine()
{
   



    return(


<div className='ML_background'>

<div className="ML_response"> 
<TopicWrapper>
                      <p className="font-Kanit text-gray-500 ">
                      Experience the diverse functionalities encompassed within the Cloudline <span className="text-orange-600">Modules.</span> 
                      </p>
                      </TopicWrapper>
                      <div className='text-black text-center font-Montserrat'> it's a sign of failure when data from equipments only end up on a dashboard. </div>
                      </div>

<style >{`


.ML_background{
    position: relative;
    background-color: #FAFAFA;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

.ML_response {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;


     
     
   
     
   }
   
       @media screen and (min-width: 1440px) and (max-width: 3500px) {
        .ML_response {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
      
      @media screen and (min-width: 1000px) and (max-width: 1440px) {
        .ML_response {

          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
      
      @media screen and (min-width: 631px) and (max-width: 1000px) {
        .ML_response {

          display: flex;
          flex-direction: column;
      
          
        }
      }
      
      
      @media screen and (max-width: 631px) {
        .ML_response {
 
 
          display: flex;
          flex-direction: column;
          margin-bottom:30px;
          
         
          
        }
      }
      
 
 
 
 
 
 
 
  `}</style>
</div>


    );
}