export default function Increase(){

    return (



<svg className="icon icon-tabler icon-tabler-trending-up" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M3 17l6 -6l4 4l8 -8" ></path>
   <path d="M14 7l7 0l0 7" ></path>
</svg>




    );
}


