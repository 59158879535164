import React from 'react';
import styled from 'styled-components';
import './Cr.css';
import Image_Content_Cr from './Image_Content_Cr';

const ImageContainer = styled.div`
  position: relative;
  z-index: 1;
`;

const Image = styled.img`
/* Set your desired maximum width */
  z-index:1;
  height: auto;

  @media screen and (min-width: 1791px) and (max-width: 5500px) {
    width: 100%;
  }
  @media screen and (max-width: 1791px) {
    max-width: 2600px;
  }
`;
const ImageMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(48, 48, 53, 0.9);/* Adjust the alpha value for desired transparency */
`;

export default function Image_Cr() {
  return (
    <div className='container_As'>
      <ImageContainer>
        <Image src="./Metaverse_Cr.png" alt="Career Page" />
        <ImageMask />
      </ImageContainer>
      <Image_Content_Cr></Image_Content_Cr>
    </div>
  );
}
