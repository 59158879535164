import React, { useLayoutEffect } from 'react'

import styled from 'styled-components'




import Bell from '../svgs/Bell.js'
import Forecast from '../svgs/Forecast.js'
import Increase from '../svgs/Increase.js'
import Planning from '../svgs/Planning.js'

import Reliablity from '../svgs/Reliablity.js'







export default function Module1Card() {
  

  return (
    

<div>
    <ModuleHeading className='text-3xl font-bold font-Montserrat tracking-tight'> Convert unplanned downtime to Planned Stopages!! </ModuleHeading>

    
        <ModuleWrapper>

                       

{/* Module 1 - Intelliscope -  Remote Asset Monitoring -----------------------------------------------------------------------*/}

                                <div>
                                  <div className='ModuleCard'> 
                                                          <div className='relative py-4 text-center '> Breakdown Prediction </div>
                                                              <div>
                                                                  <div className="font-Montserrat text-xs text-black p-2 flex">
                                                                      <span className="ml-2"><Bell/></span>
                                                                      <span className="ml-2">Mobile App based notifications</span>
                                                                  </div>
                                                                  <div className="font-Montserrat text-xs text-black p-2 flex">
                                                                      <span className="ml-2"><Forecast/></span>
                                                                      <span className="ml-2">Risk Forecasting</span>
                                                                  </div>
                                                                  <div className="font-Montserrat text-xs text-black p-2 flex">
                                                                      <span className="ml-2 text-green-600 "><Increase/></span>
                                                                      <span className="ml-2">Increase Equipment uptime</span>
                                                                  </div>
                                                                  <div className="font-Montserrat text-xs text-black p-2 flex">
                                                                      <span className="ml-2"><Planning/></span>
                                                                      <span className="ml-2">Improved Maintenance Planning</span>
                                                                  </div>
                                                                  <div className="font-Montserrat text-xs text-black p-2 flex">
                                                                      <span className="ml-2"><Reliablity/></span>
                                                                      <span className="ml-2">Enhanced Equipment Reliability</span>
                                                                  </div>  
                                                              </div>
                                    </div>
                                </div>    

{/*SLASH Module 1 - Intelliscope -  Remote Asset Monitoring -----------------------------------------------------------------------*/}


                                <div>                                
                                      {/* <div className="vertical-line"></div> */}

                                      <div>
                                        <ModuleContent1 className=' text-slate-700'>
                                        <VerticalL1 className='text-white'>.</VerticalL1>
                                         Through our mobile app-based notifications, you will be kept up to date on equipment performance and required maintenance. Utilize our risk and failure prediction capabilities to proactively address prospective problems and increase equipment uptime. With enhanced maintenance planning, maintenance activities can be scheduled in advance, thereby minimizing unanticipated outages. Experience increased equipment dependability and gain command over your downtime, thereby optimizing your productivity and minimizing disruptions.
                                        </ModuleContent1></div>
                                        
                                        
                                      
                                  </div>      

          </ModuleWrapper>
    </div>
  
  )
}

const ModuleHeading = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
  background: linear-gradient(174deg, #0c96f1, #1623db, #d1082d);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            /* Media Queries */
            @media screen and (min-width: 1440px) and (max-width: 3500px) {
              padding-top: 1%;
              padding-bottom: 1%;
            }
            
            @media screen and (min-width: 1000px) and (max-width: 1440px) {
              padding-top: 1%;
              padding-bottom: 2%;
            }
            
            @media screen and (min-width: 631px) and (max-width: 1000px) {
              padding-top: 5%;
              padding-bottom: 5%;
            }
            
            @media screen and (max-width: 631px) {
              padding-top: 5%;
              padding-bottom: 5%;
            }
  
`;
const ModuleWrapper = styled.main`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  

  /* Media Queries */
  @media screen and (min-width: 1440px) and (max-width: 3500px) {
    flex-direction: row;
    text-align: left;
    margin-left: 5%;
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    flex-direction: row;
    margin-left: 5%;
  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    flex-direction: column;
   
  }
  
  @media screen and (max-width: 631px) {
    flex-direction: column;
    
  }
  
`;




const ModuleContent1 = styled.main`
  position: relative;
  display: flex;
  align-items: center;
  width:90%;

  /* Media Queries */

  @media screen and (min-width: 2001px) {
    font-size: 23px;

  @media screen and (min-width: 1440px) and (max-width: 2000px) {
    font-size: 23px;
    padding-right: 15%;
  }

  @media screen and (min-width: 1440px) and (max-width: 2000px) {
    font-size: 23px;
    padding-right: 15%;
  }
  
  @media screen and (min-width: 1000px) and (max-width: 1440px) {
    font-size: 20px;
  }
  
  @media screen and (min-width: 631px) and (max-width: 1000px) {
    font-size: 18px;
    margin-left:5%; 
  }

  
  @media screen and (max-width: 631px) {
    padding-top:15%;
    margin:10%;
    font-size: 8px;
  
`;

const VerticalL1 = styled.main`
 {
width: 5px;
background-color: #fb8c00; 
margin-left:5%;
margin-right:5%;

@media screen and (min-width: 1440px) and (max-width: 3500px) {
  height: 255px;
}
@media screen and (min-width: 1000px) and (max-width: 1440px) {
  height: 255px;
}
@media screen and (min-width: 631px) and (max-width: 1000px) {
  height: 255px;
}
@media screen and (min-width: 450px) and (max-width: 631px) {
  height: 355px;
}
@media screen and (min-width: 400px) and (max-width: 450px) {
  height: 355px;
}
@media screen and (max-width: 400px) {
  height: 380px;
}

`








