export default function Oil_Anamoly(){

    return (


 
<svg  fill="#000000" width="80px" height="80px" version="1.1" id="Layer_1" viewBox="0 0 496 496" >
<defs>
  <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
    <stop offset="0%" stopColor="#cc2b5e" />
    <stop offset="100%" stopColor="#753a88" />
  </linearGradient>
</defs>
<g>
	<g>
		<g>
			<rect  x="144" y="104" width="16" height="24"/>
			<rect x="176" y="104" width="16" height="24"/>
			<rect x="208" y="104" width="16" height="24"/>
			<rect x="240" y="104" width="16" height="24"/>
			<rect x="272" y="104" width="16" height="24"/>
			<rect x="304" y="104" width="16" height="24"/>
			<rect x="336" y="104" width="16" height="24"/>
			<rect x="144" y="368" width="16" height="24"/>
			<rect x="176" y="368" width="16" height="24"/>
			<rect x="208" y="368" width="16" height="24"/>
			<rect x="240" y="368" width="16" height="24"/>
			<rect x="272" y="368" width="16" height="24"/>
			<rect x="304" y="368" width="16" height="24"/>
			<rect x="336" y="368" width="16" height="24"/>
			<rect x="368" y="144" width="24" height="16"/>
			<rect x="368" y="176" width="24" height="16"/>
			<rect x="368" y="208" width="24" height="16"/>
			<rect x="368" y="240" width="24" height="16"/>
			<rect x="368" y="272" width="24" height="16"/>
			<rect x="368" y="304" width="24" height="16"/>
			<rect x="368" y="336" width="24" height="16"/>
			<rect x="104" y="144" width="24" height="16"/>
			<rect x="104" y="176" width="24" height="16"/>
			<rect x="104" y="208" width="24" height="16"/>
			<rect x="104" y="240" width="24" height="16"/>
			<rect x="104" y="272" width="24" height="16"/>
			<rect x="104" y="304" width="24" height="16"/>
			<rect x="104" y="336" width="24" height="16"/>
			<path fill="url(#gradient)" d="M456,46.528v85.192L437.576,144H408v16h34.424L472,140.28V46.528c9.288-3.312,16-12.112,16-22.528     c0-13.232-10.768-24-24-24s-24,10.768-24,24C440,34.416,446.704,43.216,456,46.528z M464,16c4.416,0,8,3.584,8,8s-3.584,8-8,8     c-4.416,0-8-3.584-8-8S459.584,16,464,16z"/>
			<rect x="408" y="176" width="88" height="16"/>
			<rect x="408" y="208" width="88" height="16"/>
			<polygon points="460.28,240 408,240 408,256 451.72,256 467.72,280 496,280 496,264 476.28,264    "/>
			<rect x="408" y="272" width="32" height="16"/>
			<rect x="408" y="304" width="88" height="16"/>
			<path fill="url(#gradient)" d="M435.312,336H408v16h20.688L448,371.312v14.16c-9.296,3.312-16,12.112-16,22.528c0,13.232,10.768,24,24,24     s24-10.768,24-24c0-10.416-6.712-19.216-16-22.528v-20.784L435.312,336z M456,416c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8     c4.416,0,8,3.584,8,8C464,412.416,460.416,416,456,416z"/>
			<polygon points="352,428.688 352,408 336,408 336,435.312 364.688,464 496,464 496,448 371.312,448    "/>
			<polygon points="320,460.688 320,408 304,408 304,467.312 332.688,496 496,496 496,480 339.312,480    "/>
			<rect x="272" y="408" width="16" height="88"/>
			<polygon points="240,416 224,416 224,408 208,408 208,432 256,432 256,408 240,408    "/>
			<path fill="url(#gradient)" d="M232,448c-9.904,0-18.416,6.032-22.08,14.608L192,444.688V408h-16v43.312L204.688,480h4.784     c3.312,9.288,12.112,16,22.528,16c13.232,0,24-10.768,24-24S245.232,448,232,448z M232,480c-4.416,0-8-3.584-8-8     c0-4.416,3.584-8,8-8s8,3.584,8,8C240,476.416,236.416,480,232,480z"/>
			<path fill="url(#gradient)" d="M144,444.688L124.688,464h-78.16c-2.416-6.776-7.752-12.112-14.528-14.528v-110.16L51.312,320H88v-16H44.688L16,332.688     v116.784C6.704,452.784,0,461.584,0,472c0,13.232,10.768,24,24,24c10.416,0,19.216-6.712,22.528-16h84.784L160,451.312V408h-16     V444.688z M24,480c-4.416,0-8-3.584-8-8c0-4.416,3.584-8,8-8s8,3.584,8,8C32,476.416,28.416,480,24,480z"/>
			<path fill="url(#gradient)" d="M83.312,352H88v-16H76.688L48,364.688v22.624L76.688,416h4.784c3.312,9.288,12.112,16,22.528,16     c13.232,0,24-10.768,24-24s-10.768-24-24-24c-9.904,0-18.416,6.032-22.08,14.608L64,380.688v-9.376L83.312,352z M104,400     c4.416,0,8,3.584,8,8c0,4.416-3.584,8-8,8s-8-3.584-8-8C96,403.584,99.584,400,104,400z"/>
			<rect y="272" width="88" height="16"/>
			<path fill="url(#gradient)" d="M16,46.528v180.784L44.688,256H88v-16H51.312L32,220.688v-41.376L44.688,192H88v-16H51.312L32,156.688V46.528     C41.288,43.216,48,34.416,48,24C48,10.768,37.232,0,24,0S0,10.768,0,24C0,34.416,6.704,43.216,16,46.528z M24,16     c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8S19.584,16,24,16z"/>
			<rect x="56" y="208" width="32" height="16"/>
			<path fill="url(#gradient)" d="M48,131.312L76.688,160H88v-16h-4.688L64,124.688V51.312L83.312,32H176v56h16V32h16v56h16V32h16v56h16V64l16,12v12h16V68     l-32-24V32h9.472c3.312,9.288,12.112,16,22.528,16c13.232,0,24-10.768,24-24S301.232,0,288,0c-10.416,0-19.216,6.712-22.528,16     H76.688L48,44.688V131.312z M288,16c4.416,0,8,3.584,8,8s-3.584,8-8,8c-4.416,0-8-3.584-8-8S283.584,16,288,16z"/>
			<path fill="url(#gradient)" d="M160,64h-33.472c-3.312-9.288-12.112-16-22.528-16c-13.232,0-24,10.768-24,24s10.768,24,24,24     c10.416,0,19.216-6.712,22.528-16H144v8h16V64z M104,80c-4.416,0-8-3.584-8-8s3.584-8,8-8s8,3.584,8,8S108.416,80,104,80z"/>
			<path fill="url(#gradient)" d="M336,88h16v-8h41.472c3.312,9.288,12.112,16,22.528,16c13.232,0,24-10.768,24-24s-10.768-24-24-24     c-10.416,0-19.216,6.712-22.528,16H336V88z M416,64c4.416,0,8,3.584,8,8s-3.584,8-8,8c-4.416,0-8-3.584-8-8S411.584,64,416,64z"/>
			<polygon points="304,88 320,88 320,67.312 344,43.312 344,0 328,0 328,36.688 304,60.688    "/>
			<path fill="url(#gradient)" d="M272,272c26.472,0,48-21.528,48-48s-21.528-48-48-48s-48,21.528-48,48S245.528,272,272,272z M272,192     c17.648,0,32,14.352,32,32s-14.352,32-32,32s-32-14.352-32-32S254.352,192,272,192z"/>
			<path fill="url(#gradient)" d="M241.416,297.896C250.848,301.816,261.168,304,272,304c44.112,0,80-35.888,80-80s-35.888-80-80-80s-80,35.888-80,80     c0,10.832,2.184,21.152,6.104,30.584l-45.136,45.136c-5.784,5.784-8.968,13.472-8.968,21.656     c0,16.88,13.744,30.624,30.624,30.624c8.184,0,15.872-3.184,21.656-8.968L241.416,297.896z M272,160c35.288,0,64,28.712,64,64     c0,35.288-28.712,64-64,64c-35.288,0-64-28.712-64-64C208,188.712,236.712,160,272,160z M174.624,336     C166.56,336,160,329.44,160,321.376c0-3.856,1.56-7.624,4.28-10.344l41.8-41.8c5.576,8.096,12.592,15.112,20.688,20.688     l-41.8,41.8C182.248,334.44,178.48,336,174.624,336z"/>
		</g>
	</g>
</g>
</svg>







    );
}




